import React, { useCallback, useContext, useState } from "react";

import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  Avatar,
  DropdownItem,
} from "@nextui-org/react";
import { conversationMode, recorderStatus, DemoAvatarContext } from "../..";
import {
  AVATAR_DEMO_DATA,
  EVENT,
  INITAL_MODAL_DIALOG,
  RECORDER_STATUS,
} from "../../../const";

import AvatarSelectionDialog from "../Dialog/AvatarSelection";
import { isDesktop, isMobile } from "react-device-detect";
import useVideoStore from "../../store/videoStore";
import { motion } from "framer-motion";
import { conversationMessages } from "../VideoChat";
import { demoModalDialog, selectedAvatar } from "@/pages/demo/signal";
import { useDemoContext } from "@/pages/components/context/Demo";

function CharacterSelection({ disconnectSSE, bythenUnity }) {
  const { mosuClient } = useVideoStore();
  const { audioRecorder } = useContext(DemoAvatarContext);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const { onRequestSessionToken } = useDemoContext();

  const onChangeCharacter = useCallback(
    (avatar) => {
      disconnectSSE();

      onRequestSessionToken((sessionToken) => {
        selectedAvatar.value = {
          ...avatar,
          ...{ token: sessionToken.token, refetch: true },
        };

        if (isDesktop) {
          bythenUnity.sendMessage(
            "Manager",
            "QueueEvent",
            JSON.stringify({ event: EVENT.APP.STOP_GENERATE })
          );

          bythenUnity.sendMessage(
            "Manager",
            "QueueEvent",
            JSON.stringify({
              event: EVENT.APP.CHANGE_CHARACTER,
              data: {
                avatarID: selectedAvatar.value.avatarId,
              },
            })
          );
        } else {
          if (mosuClient) {
            mosuClient.sendMessage(EVENT.APP.STOP_GENERATE);

            mosuClient.sendMessage(EVENT.APP.CHANGE_CHARACTER, {
              data: {
                avatarID: selectedAvatar.value.avatarId,
              },
            });
          }
        }

        demoModalDialog.value = INITAL_MODAL_DIALOG;
        conversationMessages.value = [];
        if (isMobile) conversationMode.value = "voice";
        else conversationMode.value = "chat-lite";
      });
    },
    [bythenUnity, disconnectSSE, mosuClient, onRequestSessionToken]
  );

  const onClickDropdown = useCallback(
    (avatar) => {
      if (avatar.id !== selectedAvatar.value.id) {
        recorderStatus.value = RECORDER_STATUS.PAUSE;
        audioRecorder.muteMic(true);
        demoModalDialog.value = {
          ...demoModalDialog.value,
          show: true,
          body: <AvatarSelectionDialog />,
          onOk: () => onChangeCharacter(avatar),
          onCancel: () => {
            demoModalDialog.value = INITAL_MODAL_DIALOG;
            recorderStatus.value = RECORDER_STATUS.RECORD;
            audioRecorder.muteMic(false);
          },
          onClose: () => {
            demoModalDialog.value = INITAL_MODAL_DIALOG;
            recorderStatus.value = RECORDER_STATUS.RECORD;
            audioRecorder.muteMic(false);
          },
        };
      }
    },
    [audioRecorder, onChangeCharacter]
  );

  return (
    <div className="absolute top-4 lg:top-10 left-4 lg:left-10 z-[99]">
      <Dropdown
        placement="bottom-start"
        classNames={{
          content: "p-0 mt-3 lg:mt-2 rounded-xl",
        }}
        onOpenChange={(isOpen) => setIsOpenDropdown(isOpen)}
      >
        <DropdownTrigger>
          <div className="flex items-center cursor-pointer">
            <Avatar className="w-9 h-9" src={selectedAvatar.value.image} />
            <span
              className={`font-medium text-lg ml-2 ${
                conversationMode.value === "chat-full" ||
                (isMobile && conversationMode.value === "chat-lite")
                  ? "text-[#1A1919]"
                  : "text-white"
              }`}
            >
              {selectedAvatar.value.name}
            </span>

            <motion.span
              animate={{ rotateX: !isOpenDropdown ? 180 : 0 }}
              transition={{ ease: "linear", duration: 0.2 }}
            >
              <span
                style={{
                  color:
                    conversationMode.value === "chat-full" ||
                    (isMobile && conversationMode.value === "chat-lite")
                      ? "#1A1919"
                      : "white",
                  marginTop: !isOpenDropdown ? "4px" : 8,
                }}
                className="material-symbols-outlined mt-1 ml-1"
              >
                keyboard_arrow_up
              </span>
              {/* <CaretDown
                weight="bold"
                color={`${
                  conversationMode.value === "chat-full" ||
                  (isMobile && conversationMode.value === "chat-lite")
                    ? "#1A1919"
                    : "white"
                }`}
              /> */}
            </motion.span>
          </div>
        </DropdownTrigger>

        <DropdownMenu
          className="p-[6px] shadow-[0px_0px_8px_0px_rgba(0,0,0,0.08)]"
          aria-label="character-dropdown"
        >
          {AVATAR_DEMO_DATA.filter(
            (avatar) =>
              avatar.avatarId !== selectedAvatar.value.avatarId &&
              !avatar.isDisabled
          ).map((avatar, indexAvatar) => (
            <DropdownItem
              key={indexAvatar}
              aria-label={`avatar-${avatar.name}`}
              className={`gap-0 p-[6px] data-[hover=true]:bg-[#EEEDEC]`}
              onClick={() => onClickDropdown(avatar)}
            >
              <div className="flex items-center space-x-3 cursor-pointer">
                <Avatar className="w-9 h-9" src={avatar.image} />
                <span className={`text-sm lg:text-base text-[#1A1919]`}>
                  {avatar.name}
                </span>
              </div>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default CharacterSelection;
