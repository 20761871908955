import { Tooltip } from "@nextui-org/react";
import { DemoAvatarContext, conversationMode } from "../..";
import { useContext } from "react";

function ConversationToggle() {
  const { audioRecorder } = useContext(DemoAvatarContext);
  return (
    <div className="absolute w-auto z-[999] px-4 bottom-12 left-8">
      <Tooltip
        classNames={{
          base: ["before:bg-[#F5F3F0] border-0 border-[#F5F3F0]"],
          content: [
            "py-[6px] px-[12px] border-0",
            "text-[12px] leading-[16px] bg-[#F5F3F0] border-[#F5F3F0] rounded-[4px] shadow-none",
          ],
        }}
        showArrow={true}
        content={
          conversationMode.value == "chat-lite"
            ? "Turn on voice mode"
            : "Turn on chat mode"
        }
      >
        <div className="bg-[#F5F3EF] p-1 grid grid-cols-2 gap-2 rounded-[87.5px]">
          <div
            className={`bg-[#FF5C0A] absolute transition-transform duration-300 w-12 h-12 text-alabaster rounded-[87.5px] z-[1] top-1/2 -translate-y-1/2 ${
              conversationMode.value == "chat-lite"
                ? "translate-x-0"
                : "translate-x-[calc(100%+8px)]"
            }`}
          ></div>
          <button
            onClick={() => {
              conversationMode.value = "chat-lite";
            }}
            className={`relative z-[2] font-medium text-lg rounded-[87.5px] w-12 h-12 flex items-center justify-center ${
              conversationMode.value == "chat-lite"
                ? "hover:cursor-default text-alabaster"
                : "text-black/50 hover:text-black"
            }`}
          >
            <span className="!text-xl material-symbols-outlined">chat</span>
          </button>
          <button
            onClick={() => {
              conversationMode.value = "voice";
              audioRecorder.startRecording();
            }}
            className={`relative z-[2] font-medium text-lg rounded-[87.5px] w-12 h-12 flex items-center justify-center ${
              conversationMode.value == "voice"
                ? "hover:cursor-default text-alabaster"
                : "text-black/50 hover:text-black"
            }`}
          >
            <span className="!text-xl material-symbols-outlined mt-[-2px]">
              headphones
            </span>
          </button>
        </div>
      </Tooltip>
    </div>
  );
}

export default ConversationToggle;
