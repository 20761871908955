import Cookies from "js-cookie";

const BACKEND_URL = `${process.env.NEXT_PUBLIC_BYTHEN_API_URL}`;

export const getUserToken = async () => {
  try {
    const response = await fetch(`${BACKEND_URL}ms/chat-bot/v1/user_tokens`, {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();

    if (response?.status != 200) throw new Error(data.error);

    return { data, status: response.status };
  } catch (error) {
    const eror = new Error(error.message);
    // e.message is 'Could not parse input'
    throw eror;
  }
};

export const getSessionToken = async () => {
  const demoToken = Cookies.get("DEMO_TOKEN");

  if (!demoToken) throw new Error("user token not found");

  try {
    const response = await fetch(
      `${BACKEND_URL}ms/chat-bot/v1/session_tokens`,
      {
        credentials: "include",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "User-Token": demoToken,
        },
      }
    );

    const data = await response.json();

    if (response?.status != 200)
      throw new Error(JSON.stringify({ message: data.error, code: data.code }));

    return { data, status: response.status };
  } catch (error) {
    const eror = new Error(error.message);
    // e.message is 'Could not parse input'
    throw eror;
  }
};

export const getStarterConversation = async (avatarId) => {
  try {
    const response = await fetch(
      `${BACKEND_URL}ms/chat-bot/v1/chats/${avatarId}/starter`,
      {
        credentials: "include",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();

    if (response?.status != 200) throw new Error(data.error);

    return { data, status: response.status };
  } catch (error) {
    const eror = new Error(error.message);
    // e.message is 'Could not parse input'
    throw eror;
  }
};

export const reportAnswer = async (sessionToken, chatId) => {
  try {
    const response = await fetch(
      `${BACKEND_URL}ms/chat-bot/v1/chats/thumbs-down/${chatId}`,
      {
        credentials: "include",
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Session-Token": sessionToken,
        },
      }
    );

    const data = await response.json();

    if (response?.status != 200) throw new Error(data.error);

    return { data, status: response.status };
  } catch (error) {
    const eror = new Error(error.message);
    // e.message is 'Could not parse input'
    throw eror;
  }
};

export const regenerateChat = async (sessionToken) => {
  const demoToken = Cookies.get("DEMO_TOKEN");

  if (!demoToken) throw new Error("user token not found");

  try {
    const response = await fetch(
      `${BACKEND_URL}ms/chat-bot/v1/chats/regenerate`,
      {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "User-Token": demoToken,
          "Session-Token": sessionToken,
        },
      }
    );

    const data = await response.json();

    if (response?.status != 200) throw new Error(data.error);

    return { data, status: response.status };
  } catch (error) {
    const eror = new Error(error.message);
    // e.message is 'Could not parse input'
    throw eror;
  }
};
