import { isMobile } from "react-device-detect";
import { DemoAvatarContext, conversationMode } from "../..";
import { useCallback, useContext } from "react";

function CloseExpand() {
  const { audioRecorder } = useContext(DemoAvatarContext);
  const onChangeConversation = useCallback(
    (mode) => {
      conversationMode.value = mode;
      if (mode == "voice" && !audioRecorder.isRecording)
        audioRecorder.startRecording();
    },
    [audioRecorder]
  );

  return (
    <div className="flex flex-row-reverse transition-transform">
      {isMobile ? (
        <button
          className="text-white bg-[#E1DACE] p-0 h-10 w-10 flex justify-center items-center rounded-full"
          onClick={() => onChangeConversation("voice")}
        >
          <span className="!text-xl material-symbols-outlined text-[#1A1919]">
            headphones
          </span>
        </button>
      ) : (
        <>
          {conversationMode.value == "chat-lite" ? (
            <button
              className="text-white bg-[#E1DACE] p-0 h-10 w-10 flex justify-center items-center rounded-full"
              onClick={() => onChangeConversation("chat-full")}
            >
              <span className="!text-base material-symbols-outlined text-[#1A1919]">
                open_in_full
              </span>
            </button>
          ) : (
            <button
              className="text-white bg-[#E1DACE] p-0 h-10 w-10 flex justify-center items-center rounded-full"
              onClick={() => onChangeConversation("chat-lite")}
            >
              <span className="material-symbols-outlined !text-xl text-[#1A1919]">
                close_fullscreen
              </span>
            </button>
          )}
        </>
      )}
    </div>
  );
}

export default CloseExpand;
