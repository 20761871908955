import { effect, useSignal } from "@preact/signals-react";
import { memo, useContext, useEffect, useRef, useState } from "react";
import { DemoAvatarContext } from "../..";

const LiveAudioVisualizer = memo(
  ({ mediaRecorder, colorBar, checkIdleAndThreshold, barNumber }) => {
    const { audioRecorder } = useContext(DemoAvatarContext);
    const context = useSignal(new AudioContext());
    const [analyzer, setAnalyzer] = useState();
    const barArr = useSignal([]);
    const barContainerRef = useRef(null);
    const isON = useSignal(true);

    useEffect(() => {
      return () => {
        if (context.value) context.value.close();
        if (analyzer) analyzer.disconnect();
        isON.value = false;
      };
    }, []);

    useEffect(() => {
      if (!mediaRecorder.stream || !context.value) return;
      const analyserNode = context.value?.createAnalyser();

      if (!analyserNode) return;

      setAnalyzer(analyserNode);
      analyserNode.fftSize = 128;
      analyserNode.minDecibels = -45;
      analyserNode.maxDecibels = -20;
      analyserNode.smoothingTimeConstant = 0.4;
      const source = context.value.createMediaStreamSource(
        mediaRecorder.stream
      );
      source.connect(analyserNode);
    }, [mediaRecorder]);

    useEffect(() => {
      if (analyzer && mediaRecorder.state === "recording") {
        report();
      }
    }, [analyzer, mediaRecorder.state]);

    useEffect(() => {
      if (audioRecorder.recordingTime > 0) {
        if (typeof checkIdleAndThreshold === "function" && isON.value) {
          // checkIdleAndThreshold(barHeight);
          const sum = barArr.value.reduce(
            (accumulator, current) => accumulator + current,
            0
          );

          checkIdleAndThreshold(Math.floor(sum / barArr.value.length));
        }
      }
    }, [audioRecorder.recordingTime]);

    const report = () => {
      if (!analyzer || !context.value) return;

      const data = new Uint8Array(analyzer?.frequencyBinCount);

      if (mediaRecorder.state === "recording") {
        analyzer?.getByteFrequencyData(data);
        barArr.value = calculateDataBar(data);
        requestAnimationFrame(report);
      } else if (mediaRecorder.state === "paused") {
        barArr.value = calculateDataBar(data);
        if (typeof setIsIdle !== "undefined") setIsIdle(false);
      } else if (
        mediaRecorder.state === "inactive" &&
        context.value.state !== "closed"
      ) {
        // context.value.close();
        if (typeof setIsIdle !== "undefined") setIsIdle(false);
      }
    };

    const calculateDataBar = (freqData) => {
      let step = Math.floor(freqData.length / 36);
      const barHeightFactor = 100 / 256;

      if (barNumber > freqData.length) {
        barNumber = freqData.length;
        step = 1;
      }

      const data = [];
      for (let i = 0; i < barNumber; i++) {
        let sum = 0;

        for (let j = 0; j < step && i * step + j < freqData.length; j++) {
          sum += freqData[i * step + j];
        }

        let barHeight = freqData[i] * barHeightFactor;

        if (barHeight < 8) barHeight = 8;

        if (barHeight > 15) barHeight = 15;
        data.push(barHeight);
      }

      return data;
    };

    effect(() => {
      const barContainer = barContainerRef.current;

      if (barContainer) {
        // Clear existing content
        barContainer.innerHTML = "";

        // Append new SVG elements
        barArr.value.forEach((d, i) => {
          const svgElement = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "svg"
          );
          svgElement.setAttribute("width", "8");
          svgElement.setAttribute("height", d.toString() ? d.toString() : "8");
          svgElement.setAttribute("viewBox", `0 0 8 ${d}`);
          svgElement.setAttribute("fill", "none");
          svgElement.setAttribute("style", "width: 10px;");

          const rectElement = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "rect"
          );
          rectElement.setAttribute("x", "0.5");
          rectElement.setAttribute("width", "8");
          rectElement.setAttribute("height", d.toString() ? d.toString() : "8");
          rectElement.setAttribute("rx", d.toString() ? "4" : "8");
          rectElement.setAttribute("ry", d.toString() ? "4" : "8");
          rectElement.setAttribute("fill", colorBar ?? "black");

          svgElement.appendChild(rectElement);
          barContainer.appendChild(svgElement);
        });
      }
    });

    return (
      <div
        ref={barContainerRef}
        className="w-full h-full flex justify-center items-center"
      ></div>
    );
  }
);

LiveAudioVisualizer.displayName = "LiveAudioVisualizer";

export default LiveAudioVisualizer;
