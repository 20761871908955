import { ENVIRONMENT } from "@/utils/environment";
import { FireSimple, Heart, PersonSimpleCircle } from "@phosphor-icons/react";

export const EVENT = {
  // Internal Events
  RECONNECTING: "RECONNECTING",
  CONNECTING: "CONNECTING",
  CONNECTED: "CONNECTED",
  DISCONNECTED: "DISCONNECTED",
  TRACK: "TRACK",
  MESSAGE: "MESSAGE",
  DATA: "DATA",

  // Websocket Events
  SYSTEM: {
    INIT: "system/init",
    DISCONNECT: "system/disconnect",
    ERROR: "system/error",
  },
  SIGNAL: {
    OFFER: "signal/offer",
    ANSWER: "signal/answer",
    PROVIDE: "signal/provide",
    CANDIDATE: "signal/candidate",
  },
  MEMBER: {
    LIST: "member/list",
    CONNECTED: "member/connected",
    DISCONNECTED: "member/disconnected",
  },
  BROADCAST: {
    STATUS: "broadcast/status",
    CREATE: "broadcast/create",
    DESTROY: "broadcast/destroy",
  },
  APP: {
    VOICE: "app/chatbot-voice-send",
    START_PROCESS: "app/chatbot-start-process",
    END_PROCESS: "app/chatbot-end-process",
    CHANGE_MODE: "app/setting-change-mode",
    CHANGE_CHARACTER: "app/setting-change-character",
    STOP_GENERATE: "app/stop-generate",
    TOGGLE_DEBUG: "app/toggle-debug",
    NO_RESULT: "app/recording-no-result",
  },
};

export const SessionStrorageKey = {
  CHAT_HISTORY: "chat_history",
  CHAT_MODE: "chat_mode",
  CHAT_TOKEN: "chat_token",
  CHAT_RANDOM_DEVICE: "chat_random_device",
};

export const RECORDER_STATUS = {
  INITIAL: "initial",
  PAUSE: "pause",
  PLAY: "play",
  RECORD: "record",
  PROCESS: "process",
  STOP: "stop",
  INTERUPT: "interupt",
};

export const AVATAR_DEMO_DATA = [
  {
    id: 3,
    avatarId: "azuki",
    name: "Raiju",
    role: "Lightning God and Observer",
    isDisabled: false,
    isPrivate: true,
    primaryColor: "#A9A9F8",
    firstStripeBg: "#A9A9F8",
    bgImage: `${ENVIRONMENT.ASSETS_URL}/demo/azuki-bg.webp`,
    fgImage: `${ENVIRONMENT.ASSETS_URL}/demo/azuki-fg.webp`,
    bgPodImageDesktop: `${ENVIRONMENT.ASSETS_URL}/demo/azuki-pod-bg-desktop.webp`,
    bgPodImageMobile: `${ENVIRONMENT.ASSETS_URL}/demo/azuki-pod-bg-mobile.webp`,
    podImage: `${ENVIRONMENT.ASSETS_URL}/bythen-pod/pod-azuki.webp`,
    image: `${ENVIRONMENT.ASSETS_URL}/demo/azuki-avatar.webp`,
    info: "Meet the ancient storm bringer, summoned by one of bythen’s investors. His presence reveals a new dimension to the potential of bytes.",
    borderClass: "data-[hover=true]:border-pumpkin-400",
    loaderBG: "bg-[#A9A9F8]",
    poseImage: `${ENVIRONMENT.ASSETS_URL}/demo/azuki-pose.webp`,
    cardImage: `${ENVIRONMENT.ASSETS_URL}/demo/azuki-slider.webp`,
    firstPropsImage: `${ENVIRONMENT.ASSETS_URL}/demo/azuki-props-1.webp`,
    secondPropsImage: `${ENVIRONMENT.ASSETS_URL}/demo/azuki-props-2.webp`,
    textImage: `${ENVIRONMENT.ASSETS_URL}/demo/azuki-text.svg`,
    messageID: 27,
    tag: [
      {
        name: "Charismatic",
        icon: () => <FireSimple weight="fill" width={12} height={12} />,
      },
      {
        name: "Calm",
        icon: () => <Heart weight="fill" width={12} height={12} />,
      },
    ],
    specs: [
      {
        label: "ORIGIN",
        icon: () => <PersonSimpleCircle width={20} height={20} weight="fill" />,
        value: "The Garden",
      },
      {
        label: "TYPE",
        icon: () => <PersonSimpleCircle width={20} height={20} weight="fill" />,
        value: "Lightning God",
      },
    ],
  },
  {
    id: 1,
    avatarId: "penguin",
    name: "Mateo",
    role: "Social Media Intern",
    isDisabled: false,
    isPrivate: false,
    primaryColor: "#1350B8",
    firstStripeBg: "#FFC06E",
    bgImage: `${ENVIRONMENT.ASSETS_URL}/demo/pudgy-bg.webp`,
    fgImage: `${ENVIRONMENT.ASSETS_URL}/demo/pudgy-fg.webp`,
    bgPodImageDesktop: `${ENVIRONMENT.ASSETS_URL}/demo/pudgy-pod-bg-desktop.webp`,
    bgPodImageMobile: `${ENVIRONMENT.ASSETS_URL}/demo/pudgy-pod-bg-mobile.webp`,
    podImage: `${ENVIRONMENT.ASSETS_URL}/bythen-pod/pod-pudgy.webp`,
    image: `${ENVIRONMENT.ASSETS_URL}/demo/pudgy-avatar.webp`,
    info: "Say hello to bythen’s one and only intern. Claiming to be the best, prepare for his arrogant persona and boastful acts!",
    borderClass: "data-[hover=true]:border-[#FFAF70]",
    loaderBG: "bg-[#FFAF70]",
    poseImage: `${ENVIRONMENT.ASSETS_URL}/demo/pudgy-pose.webp`,
    cardImage: `${ENVIRONMENT.ASSETS_URL}/demo/pudgy-slider.webp`,
    firstPropsImage: `${ENVIRONMENT.ASSETS_URL}/demo/pudgy-props-1.webp`,
    secondPropsImage: `${ENVIRONMENT.ASSETS_URL}/demo/pudgy-props-2.webp`,
    textImage: `${ENVIRONMENT.ASSETS_URL}/demo/pudgy-text.svg`,
    stripeImage: `${ENVIRONMENT.ASSETS_URL}/demo/pudgy-stripe.webp`,
    messageID: 25,
    tag: [
      {
        name: "Mischievous",
        icon: () => <FireSimple weight="fill" width={12} height={12} />,
      },
      {
        name: "Overconfident",
        icon: () => <Heart weight="fill" width={12} height={12} />,
      },
    ],
    specs: [
      {
        label: "ORIGIN",
        icon: () => <PersonSimpleCircle width={20} height={20} weight="fill" />,
        value: "The Antarctic",
      },
      {
        label: "TYPE",
        icon: () => <PersonSimpleCircle width={20} height={20} weight="fill" />,
        value: "Penguin",
      },
    ],
  },
  {
    id: 2,
    avatarId: "bayc",
    name: "Archie",
    role: "Social Media Manager",
    isDisabled: false,
    isPrivate: false,
    primaryColor: "#2C7030",
    firstStripeBg: "#CE80B3",
    bgImage: `${ENVIRONMENT.ASSETS_URL}/demo/bayc-bg.webp`,
    fgImage: `${ENVIRONMENT.ASSETS_URL}/demo/bayc-fg.webp`,
    bgPodImageDesktop: `${ENVIRONMENT.ASSETS_URL}/demo/bayc-pod-bg-desktop.webp`,
    bgPodImageMobile: `${ENVIRONMENT.ASSETS_URL}/demo/bayc-pod-bg-mobile.webp`,
    podImage: `${ENVIRONMENT.ASSETS_URL}/bythen-pod/pod-bayc.webp`,
    image: `${ENVIRONMENT.ASSETS_URL}/demo/bayc-avatar.webp`,
    info: "A Web3 veteran with an all time high ambition but always experiencing bad lucks. Prepare for his unfiltered opinions and endless rants!",
    borderClass: "data-[hover=true]:border-[#E581D2]",
    loaderBG: "bg-[#E581D2]",
    poseImage: `${ENVIRONMENT.ASSETS_URL}/demo/bayc-pose.webp`,
    cardImage: `${ENVIRONMENT.ASSETS_URL}/demo/bayc-slider.webp`,
    firstPropsImage: `${ENVIRONMENT.ASSETS_URL}/demo/bayc-props-1.webp`,
    secondPropsImage: `${ENVIRONMENT.ASSETS_URL}/demo/bayc-props-2.webp`,
    textImage: `${ENVIRONMENT.ASSETS_URL}/demo/bayc-text.svg`,
    stripeImage: `${ENVIRONMENT.ASSETS_URL}/demo/bayc-stripe.webp`,
    messageID: 26,
    tag: [
      {
        name: "Grumpy",
        icon: () => <FireSimple weight="fill" width={12} height={12} />,
      },
      {
        name: "Sarcastic",
        icon: () => <Heart weight="fill" width={12} height={12} />,
      },
    ],
    specs: [
      {
        label: "ORIGIN",
        icon: () => <PersonSimpleCircle width={20} height={20} weight="fill" />,
        value: "The Clubhouse",
      },
      {
        label: "TYPE",
        icon: () => <PersonSimpleCircle width={20} height={20} weight="fill" />,
        value: "Ape",
      },
    ],
  },
  {
    avatarId: "sorayama",
    name: "hs",
    isPrivate: true,
    image: `${ENVIRONMENT.ASSETS_URL}/demo/pudgy-avatar.webp`,
    messageID: 28,
    loaderBG: "bg-[#FFF5B5]",
    isDisabled: true,
  },
  {
    avatarId: "bayc-2960",
    name: "aaronhaber",
    isPrivate: true,
    credentials: {
      username: "aaron",
      password: "BAYC#2960",
    },
    image: `${ENVIRONMENT.ASSETS_URL}/demo/ad-hoc/bayc-2960.png`,
    messageID: 29,
    loaderBG: "bg-[#6F5E70]",
    isDisabled: true,
  },
  {
    avatarId: "penguin-6726",
    name: "nat",
    isPrivate: true,
    image: `${ENVIRONMENT.ASSETS_URL}/demo/ad-hoc/pudgy-1647.png`,
    messageID: 30,
    loaderBG: "bg-[#B5B3DA]",
    isDisabled: true,
  },
  {
    avatarId: "don_pengu",
    name: "don_pengu",
    isPrivate: false,
    image: `${ENVIRONMENT.ASSETS_URL}/demo/ad-hoc/donpengu-avatar.webp`,
    messageID: 31,
    loaderBG: "bg-[#3858D8]",
    isDisabled: true,
  },
  {
    avatarId: "penguin-1647",
    name: "penguin-1647",
    isPrivate: true,
    image: `${ENVIRONMENT.ASSETS_URL}/demo/ad-hoc/pudgy-1647.png`,
    messageID: 28,
    loaderBG: "bg-[#98CDCD]",
    isDisabled: true,
  },
];

export const INITAL_MODAL_DIALOG = {
  show: false,
  body: null,
  backgroundClose: true,
  hideCloseButton: false,
  backdrop: "blur",
  baseClass: "",
  bodyClass: "",
  closeButtonClass: "",
  onOk: () => {},
  onCancel: () => {},
};
