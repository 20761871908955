import { signal } from "@preact/signals-react";
import { INITAL_MODAL_DIALOG } from "../const";

export const demoModalDialog = signal(INITAL_MODAL_DIALOG);
export const selectedAvatar = signal({});
export const isLoadingAvatar = signal(true);
export const chatToken = signal("");
export const waitingList = signal(false);
export const isHighDemand = signal(false);
export const isShowBythenWatermark = signal(false);
