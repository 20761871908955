// import type Vue from 'vue';
import { BaseClient } from "./base";
import { EVENT } from "../../const";
// import type { accessor } from '~/store';

import { ENVIRONMENT } from "@/utils/environment";

export class MosuClient extends BaseClient {
  handleTrack;
  handleConnecting;
  handleConnected;
  handleDisconnected;
  handleReconnecting;
  handleEvent;

  url;

  constructor({
    handleTrack,
    handleConnecting,
    handleConnected,
    handleDisconnected,
    handleReconnecting,
    handleEvent,
  }) {
    super();
    this.init();
    this.handleTrack = handleTrack;
    this.handleConnected = handleConnected;
    this.handleConnecting = handleConnecting;
    this.handleReconnecting = handleReconnecting;
    this.handleDisconnected = handleDisconnected;
    this.handleEvent = handleEvent;
  }

  init() {
    const url = ENVIRONMENT.API_SOCKET;
    this.url = url;
  }

  login(token) {
    this.connect(this.url, token);
  }

  logout() {
    console.log("is logged out");
    this.disconnect();
  }

  /////////////////////////////
  // Internal Events
  /////////////////////////////
  [EVENT.RECONNECTING]() {
    // reconnecting
    console.log("reconnecting =====");
    if (this.handleReconnecting) this.handleReconnecting();
  }

  [EVENT.CONNECTING]() {
    if (this.handleConnecting) this.handleConnecting();
  }

  [EVENT.CONNECTED]() {
    if (this.handleConnected) this.handleConnected();
  }

  [EVENT.DISCONNECTED](reason) {
    console.log("reason", reason);
    if (this.handleDisconnected) this.handleDisconnected(reason);
  }

  [EVENT.TRACK](event) {
    const { track, streams } = event;
    if (track.kind === "audio") {
      return;
    }
    this.handleTrack(event);
  }

  [EVENT.MESSAGE](event) {
    if (this.handleEvent) this.handleEvent(event);
  }

  [EVENT.DATA]() {}

  /////////////////////////////
  // System Events
  /////////////////////////////

  [EVENT.SYSTEM.DISCONNECT]({ message }) {
    //TODO: system disconnect
  }

  [EVENT.SYSTEM.ERROR]({ title, message }) {
    //TODO: system error
  }

  /////////////////////////////
  // Member Events
  /////////////////////////////
  [EVENT.MEMBER.LIST]({ members }) {
    //TODO: member list
  }

  [EVENT.MEMBER.CONNECTED](member) {
    //TODO: kalau connected
  }

  [EVENT.MEMBER.DISCONNECTED]({ id }) {
    //TODO kalau disconnected
  }
}
