function AbanoubLoader() {
  return (
    <div className="relative w-12 h-12 animate-loadspin">
      <div className="circle absolute top-0 left-0 bg-[#1A1919] w-5 h-5 rounded-full"></div>
      <div className="circle absolute top-0 right-0 bg-[#1A1919] w-5 h-5 rounded-full"></div>
      <div className="circle absolute bottom-0 left-0 bg-[#1A1919] w-5 h-5 rounded-full"></div>
      <div className="circle absolute bottom-0 right-0 bg-[#1A1919] w-5 h-5 rounded-full"></div>
    </div>
  );
}

export default AbanoubLoader;
