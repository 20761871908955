import { Button } from "@nextui-org/react";
import React from "react";
import { conversationMode, recorderStatus } from "../..";
import { RECORDER_STATUS } from "@/pages/demo/const";

function ChatButton() {
  if (recorderStatus.value === RECORDER_STATUS.INITIAL) return null;
  return (
    <Button
      className="w-[62px] h-[54px] bg-[#EEEDEC] data-[hover=true]:brightness-90 data-[hover=true]:opacity-100 data-[hover=true]:blur-none"
      color="primary"
      style={{ opacity: 100 }}
      isIconOnly
      size="lg"
      aria-label="Chat"
      radius="full"
      onPress={() => (conversationMode.value = "chat-lite")}
    >
      <span className="material-symbols-outlined text-[#000000]">chat</span>
    </Button>
  );
}

export default ChatButton;
