import { create } from "zustand";
import { chatToken } from "../../signal";
import { MosuClient } from "../services/socket";

export const WS_STATUS = {
  CONNECTING: "connecting",
  RECONNECTING: "reconnecting",
  CONNECTED: "connected",
  PAUSE: "pause",
  LOADING: "loading",
  PLAY: "play",
  PROCESS: "process",
  DISCONNECTED: "disconnected",
};

const useVideoStore = create((set, get) => ({
  muted: false,
  changeMuted: (mute) => set(() => ({ muted: mute })),
  initialRTC: false,
  disconnectReason: "",
  init: () => {
    const mosuClient = new MosuClient({
      handleTrack: (e) => {
        if (get().initialRTC) return;
        set(() => ({ stream: e.streams[0], initialRTC: true }));
      },
      handleConnecting: () => {
        set(() => ({ status: WS_STATUS.CONNECTING }));
      },
      handleConnected: () => {
        set(() => ({ status: WS_STATUS.CONNECTED }));
      },
      handleDisconnected: (reason) => {
        set(() => ({
          status: WS_STATUS.DISCONNECTED,
          disconnectReason: reason,
          initialRTC: false,
        }));
      },
      handleReconnecting: () => {
        set(() => ({ status: WS_STATUS.RECONNECTING, initialRTC: false }));
      },
      handleEvent: (eventName) => {
        set(() => ({ eventMessage: eventName }));
      },
    });
    mosuClient.login(chatToken.value || "");
    set(() => ({ mosuClient }));
  },
  status: WS_STATUS.CONNECTING,
}));

export default useVideoStore;
