import { motion } from "framer-motion";
import { conversationMessages } from "../VideoChat";
import { useCallback, useEffect, useRef, useState } from "react";
import { CaretRight } from "@phosphor-icons/react";
import { conversationMode } from "../..";

function FloatingVideo({ wsStream }) {
  const dragConstraintRef = useRef();
  const videoRef = useRef();
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);

  useEffect(() => {
    if (videoRef.current && wsStream) {
      if ("srcObject" in videoRef.current) {
        videoRef.current.srcObject = wsStream;
      } else {
        // @ts-ignore
        videoRef.current.src = window.URL.createObjectURL(wsStream); // for older browsers
      }
    }
  }, [wsStream]);

  const onHideVideo = useCallback(() => {
    conversationMode.value = "chat-full";
  }, []);

  return (
    <>
      <motion.div
        className={`w-[calc(100vw-32px)] h-[var(--dragarea-height)] absolute top-20 left-1/2 -translate-x-1/2`}
        ref={dragConstraintRef}
      ></motion.div>
      <motion.div
        drag={isAnimationComplete ? true : false}
        dragConstraints={dragConstraintRef}
        initial={{
          right: -150,
        }}
        animate={{
          right: 16,
          transition: {
            ease: "easeIn",
          },
        }}
        exit={{
          x: 180,
          transition: {
            ease: "easeInOut",
            duration: conversationMode.value === "voice" ? 0 : 0.3,
          },
        }}
        onAnimationComplete={() => setIsAnimationComplete(true)}
        className={`absolute w-[120px] h-[160px] bottom-[var(--bottom-pos-video)] z-[991]`}
      >
        <div
          id="video-container"
          className="w-full flex justify-center items-center h-full relative"
        >
          <video
            ref={videoRef}
            autoPlay
            webkit-playsinline="true"
            playsInline
            className="w-full h-full object-cover rounded-2xl"
          >
            Your browser does not support the video tag.
          </video>
          <button
            onClick={onHideVideo}
            className="absolute top-[6px] right-[6px] p-2 bg-[#F5F3EF] rounded-full"
          >
            <span
              style={{
                display: "block",
                lineHeight: 1,
                fontSize: "1.25rem",
              }}
              className="material-symbols-outlined"
            >
              chevron_right
            </span>
          </button>
        </div>
      </motion.div>
    </>
  );
}

export default FloatingVideo;
