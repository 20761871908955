import { demoModalDialog } from "@/pages/demo/signal";

function HighDemandDialog() {
  return (
    <>
      <h3 className="text-2xl lg:text-4xl font-medium self-start">
        We&apos;re Experiencing High Demand
      </h3>
      <p className="mb-7 lg:[mb-8] text-lg tracking-[0.18px] self-start">
        We&apos;re excited that you want to try our demo! Your turn is coming up
        soon.
      </p>
      <button
        onClick={() => demoModalDialog.value.onOk()}
        className="font-medium w-full bg-pumpkin-200 hover:bg-pumpkin-300 border-0 text-alabaster rounded-[56px] pt-4 lg:pt-[18px] pb-3 lg:pb-[14px] px-14"
      >
        Proceed
      </button>
    </>
  );
}

export default HighDemandDialog;
