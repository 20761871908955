import { demoModalDialog } from "@/pages/demo/signal";
import { Hourglass } from "@phosphor-icons/react";

function QueueDialog() {
  return (
    <>
      <Hourglass className="self-start" color="#EA3E00" size={48} />
      <h3 className="text-2xl lg:text-4xl font-medium self-start">
        You&apos;re on Queue
      </h3>
      <p className="mb-7 lg:[mb-8] text-lg tracking-[0.18px] self-start">
        You will join automatically when it&apos;s your turn.
      </p>
      <button
        onClick={() => demoModalDialog.value.onCancel()}
        className="font-medium w-full bg-pumpkin-200 hover:bg-pumpkin-300 border-0 text-alabaster rounded-[56px] pt-4 lg:pt-[18px] pb-3 lg:pb-[14px] px-14"
      >
        Cancel
      </button>
    </>
  );
}

export default QueueDialog;
