import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { AnimatePresence, motion } from "framer-motion";

import { signal, useSignal } from "@preact/signals-react";
import { reportAnswer } from "@/api/demo";
import AlertErrorContent from "@/components/Alert/ErrorContent";
import { alertDrawer } from "@/components/layout";
import { selectedAvatar } from "@/pages/demo/signal";
import { ENVIRONMENT } from "@/utils/environment";

import { ChatHistory } from "./ChatHistory";
import ChatSuggestion from "./ChatSuggestion";
import CloseExpand from "./CloseExpand";
import TextAreaInput from "./TextAreaInput";

import { conversationMessages, messageContent } from "../VideoChat";

import { conversationMode, recorderStatus } from "../..";
import useAudioRecorder from "../../hooks/useAudio";
import useVideoStore from "../../store/videoStore";

import { EVENT, RECORDER_STATUS } from "../../../const";

export const isNewMessage = signal(true);
export const isProcessing = signal(false);

const CHAT_ROOM_VARIANT = {
  initial: { right: "-60%" },
  "chat-lite": () => ({
    right: "2%",
    width: window.innerWidth > 1920 ? "40%" : "547px",
    transition: {
      duration: 0.4,
      ease: "easeIn",
    },
  }),
  "chat-full": {
    right: 0,
    width: "100%",
    transition: {
      duration: 0.4,
      ease: "easeIn",
    },
  },
  exit: () => ({
    right: "-60%",
    width: window.innerWidth > 1920 ? "40%" : "547px",
    transition: {
      duration: 0.4,
      ease: "easeIn",
    },
  }),
};

const CHAT_ROOM_VARIANT_MOBILE = {
  initial: {
    opacity: 0,
  },
  "chat-lite": {
    opacity: 1,
    transition: {
      duration: 0,
    },
  },
  "chat-full": {
    opacity: 1,
    transition: {
      duration: 0,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0,
    },
  },
};

export default function ChatRoom({
  handleChatSubmitted,
  disconnectSSE,
  bythenUnity,
}) {
  const errorMicChatRoom = useSignal("");
  const { mosuClient } = useVideoStore();
  const containerChat = useRef();
  const [isTranscribing, setIsTranscribing] = useState(false);

  const audioRecord = useAudioRecorder(
    { noiseSuppression: true, echoCancellation: true, channelCount: 1 },
    (err) => {
      errorMicChatRoom.value = err.message;
    }
  );

  const onStopStreamAndUnity = useCallback(() => {
    disconnectSSE();

    if (isDesktop) {
      bythenUnity &&
        bythenUnity.sendMessage(
          "Manager",
          "QueueEvent",
          JSON.stringify({ event: EVENT.APP.STOP_GENERATE })
        );
    } else {
      mosuClient && mosuClient.sendMessage(EVENT.APP.STOP_GENERATE);
    }
    recorderStatus.value = RECORDER_STATUS.RECORD;
  }, [bythenUnity, disconnectSSE, mosuClient]);

  const handleStopMessage = useCallback(() => {
    const lastIndex = conversationMessages.value.length - 1;
    conversationMessages.value = conversationMessages.value.map(
      (msg, indexMsg) => ({
        ...msg,
        isShow: lastIndex === indexMsg ? true : msg.isShow,
      })
    );
    onStopStreamAndUnity();
  }, [onStopStreamAndUnity]);

  //SEND RECORDER AUDIO TO MS-SPEECH
  const processRecordingBlob = useCallback(async () => {
    setIsTranscribing(true);
    const formData = new FormData();
    formData.append("file", audioRecord.recordingBlob, "recording.wav");
    try {
      const response = await fetch(
        `${ENVIRONMENT.API_BYTHEN}ms/speech/v1/transcriptions?provider=deepgram`,
        {
          method: "POST",
          body: formData,
        }
      );

      audioRecord.resetAudio();

      setIsTranscribing(false);

      if (response.ok) {
        const data = await response.json();
        if (data.text) {
          messageContent.value = data.text;
        }
      } else {
        console.error("Upload failed");
      }
    } catch (error) {
      console.error("Error uploading the file", error);
    }
  }, [audioRecord, setIsTranscribing]);

  const startListening = useCallback(() => {
    messageContent.value = "";
    audioRecord.startRecording();
    audioRecord.resetAudio();
  }, [audioRecord]);

  const scrollToBottom = useCallback(() => {
    if (containerChat.current) {
      containerChat.current.scrollTop = containerChat.current.scrollHeight;
    }
  }, []);

  const onSubmitMessage = useCallback(
    (regenerate) => {
      isProcessing.value = true;
      if (conversationMode.value !== "chat-full") {
        recorderStatus.value = RECORDER_STATUS.PROCESS;
      }

      handleChatSubmitted({
        isRegenerate: regenerate,
        onHandleFinish: () => {
          isProcessing.value = false;
        },
      });
    },
    [handleChatSubmitted]
  );

  const onRegenerateChat = useCallback(async () => {
    const lastIndex = conversationMessages.value.length - 1;

    onStopStreamAndUnity();

    conversationMessages.value = conversationMessages.value.map(
      (msg, indexMsg) => ({
        ...msg,
        isShow: lastIndex === indexMsg ? false : true,
        content: lastIndex === indexMsg ? [] : msg.content,
      })
    );

    onSubmitMessage(true);
  }, [onStopStreamAndUnity, onSubmitMessage]);

  const onReportAnswer = async (chatId) => {
    try {
      const response = await reportAnswer(selectedAvatar.value.token, chatId);

      conversationMessages.value = conversationMessages.value.map((msg) => ({
        ...msg,
        is_feedback_bad:
          msg.chatId === chatId
            ? response.data.is_feedback_bad
            : msg.is_feedback_bad,
      }));
    } catch (error) {
      alertDrawer.value = [
        {
          show: true,
          type: "error",
          content: <AlertErrorContent errorMessage={error.message} />,
        },
      ];
    }
  };

  const onHandleMessageInput = useCallback(
    (text) => {
      isNewMessage.value = false;

      conversationMessages.value = [
        ...conversationMessages.value,
        {
          role: "user",
          content: [text],
          isShow: true,
          processing: "",
          browseLists: [],
        },
        {
          role: "assistant",
          content: [],
          isShow: false,
          processing: "",
          browseLists: [],
        },
      ];

      messageContent.value = "";

      setTimeout(() => {
        // [ANIMATION] slide up new message
        isNewMessage.value = true;
      }, 500);

      onSubmitMessage(false);
    },
    [onSubmitMessage]
  );

  useEffect(() => {
    if (
      !audioRecord.isRecording &&
      audioRecord.recordingBlob &&
      audioRecord.recordingBlob.size > 0 &&
      !isTranscribing
    ) {
      processRecordingBlob();
    }
  }, [
    isTranscribing,
    audioRecord.isRecording,
    audioRecord.recordingBlob,
    processRecordingBlob,
  ]);

  useEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationMessages.value.length, scrollToBottom]);

  useEffect(() => {
    return () => {
      messageContent.value = "";
      audioRecord.resetAudio();
      audioRecord.stopRecording();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <motion.div
      initial="initial"
      variants={isMobile ? CHAT_ROOM_VARIANT_MOBILE : CHAT_ROOM_VARIANT}
      animate={conversationMode.value}
      exit="exit"
      className={`absolute z-50 bottom-0 h-[100dvh] w-full lg:w-auto flex flex-col justify-center items-center`}
    >
      <div
        className={`relative bg-[#F5F3EF] py-4 px-4 lg:py-8 lg:px-10 lg:rounded-3xl transition-all w-full h-full ${
          conversationMode.value == "chat-full"
            ? "lg:max-h-[calc(100vh-0rem)] lg:rounded-none lg:duration-500"
            : "lg:max-h-[calc(100vh-4rem)] lg:duration-700"
        }`}
      >
        <div
          style={{
            "--max-height":
              conversationMode.value !== "chat-lite"
                ? "100%"
                : "calc(100% - 40px)",
            "--max-height-body": "calc(100vh - 280px)",
            "--max-height-body_mobile": "calc(100vh - 80px)",
          }}
          className="w-full h-full flex flex-col"
        >
          <CloseExpand />

          <div className="flex flex-col max-w-full lg:max-w-[680px] w-full mx-auto relative h-[var(--max-height)] justify-between lg:justify-start">
            {/* Chat History */}
            {conversationMessages.value.length ? (
              <>
                {conversationMessages.value.length > 2 && (
                  <div className="w-full bg-[linear-gradient(360deg,_rgba(245,243,239,0)_78%,_rgba(245,243,239,0.5)_85%,_#F5F3EF_90%)] z-10 absolute top-0 h-[75px]"></div>
                )}
                <div
                  data-lenis-prevent
                  className="pt-[18px] p-4 overflow-y-scroll no-scrollbar max-h-[--max-height-body_mobile] lg:max-h-[--max-height-body] h-full"
                  ref={containerChat}
                >
                  <div className="flex flex-col justify-end space-y-8 mb-8">
                    {conversationMessages.value
                      .filter(
                        (_, indexMessage) =>
                          indexMessage < conversationMessages.value.length - 2
                      )
                      .map((message, indexMessage) => {
                        return (
                          <Fragment key={indexMessage}>
                            <ChatHistory
                              message={message}
                              onRegenerateChat={onRegenerateChat}
                              onReportAnswer={onReportAnswer}
                              indexMessage={false}
                            />
                          </Fragment>
                        );
                      })}
                  </div>
                  <div className="min-h-full">
                    <AnimatePresence key="chatEnterance">
                      {isNewMessage.value && (
                        <motion.div
                          initial={{ opacity: 0, y: 100 }}
                          animate={{ opacity: 1, y: [50, 0] }}
                          exit={{ opacity: 0, y: [0, -150] }}
                          transition={{ duration: 0.5 }}
                          className="flex flex-col space-y-8"
                        >
                          {conversationMessages.value.map(
                            (message, indexMessage) => (
                              <Fragment key={indexMessage}>
                                {indexMessage >
                                  conversationMessages.value.length - 3 && (
                                  <ChatHistory
                                    message={message}
                                    onRegenerateChat={onRegenerateChat}
                                    onReportAnswer={onReportAnswer}
                                    isLastMessage={
                                      indexMessage ===
                                      conversationMessages.value.length - 1
                                    }
                                  />
                                )}
                              </Fragment>
                            )
                          )}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
              </>
            ) : (
              <ChatSuggestion onHandleMessageInput={onHandleMessageInput} />
            )}

            <TextAreaInput
              audioRecord={audioRecord}
              onHandleMessageInput={onHandleMessageInput}
              handleStopMessage={handleStopMessage}
              startListening={startListening}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
}
