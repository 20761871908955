import { demoModalDialog } from "@/pages/demo/signal";

function WebsocketDisconnect({ reason }) {
  return (
    <>
      <h3 className="text-2xl font-bold">Something Went Wrong</h3>
      <p className="mb-20 text-lg text-center tracking-[0.18px]">{reason}</p>
      <button
        onClick={() => demoModalDialog.value.onOk()}
        className="w-full bg-pumpkin-200 hover:bg-pumpkin-300 border-0 text-alabaster rounded-[56px] pt-4 lg:pt-[18px] pb-3 lg:pb-[14px] px-14"
      >
        Ok
      </button>
    </>
  );
}

export default WebsocketDisconnect;
