import { useCallback, useContext, useEffect } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { motion } from "framer-motion";

import { signal } from "@preact/signals-react";
import { ENVIRONMENT } from "@/utils/environment";

import VoiceEqualizer from "./VoiceEqualizer";
import MicState from "./MicState";
import ChatButton from "./ChatButton";
import { conversationMessages } from "../VideoChat";

import { DemoAvatarContext, recorderStatus } from "../..";
import useVideoStore from "../../store/videoStore";
import { EVENT, RECORDER_STATUS } from "../../../const";

export const voiceData = signal({
  threshold: 0,
  average: 0,
  isActive: false,
  result: {
    sum: 0,
    total: 0,
  },
});

export default function VoiceAssistant({
  handleChatSubmitted,
  disconnectSSE,
  bythenUnity,
}) {
  const { mosuClient } = useVideoStore();
  const { audioRecorder } = useContext(DemoAvatarContext);

  // ON CLICK STOP BUTTON
  const handleInterupt = () => {
    disconnectSSE();

    if (!isDesktop) {
      if (mosuClient) mosuClient.sendMessage(EVENT.APP.STOP_GENERATE);
    } else {
      bythenUnity &&
        bythenUnity.sendMessage(
          "Manager",
          "QueueEvent",
          JSON.stringify({ event: EVENT.APP.STOP_GENERATE })
        );
    }
    recorderStatus.value = RECORDER_STATUS.RECORD;
  };

  //SEND RECORDER AUDIO TO MS-SPEECH
  const processRecordingBlob = useCallback(async () => {
    if (audioRecorder.recordingBlob && audioRecorder.recordingBlob.size > 0) {
      const formData = new FormData();
      formData.append("file", audioRecorder.recordingBlob, "recording.wav");
      try {
        const response = await fetch(
          `${ENVIRONMENT.API_BYTHEN}ms/speech/v1/transcriptions?provider=deepgram`,
          {
            method: "POST",
            body: formData,
          }
        );

        voiceData.value.isActive = false;
        audioRecorder.resetAudio();

        if (response.ok) {
          const data = await response.json();

          if (data.text) {
            // if (conversationMode.value === "chat-lite") {
            //   messageContent.value = data.text;
            //   recorderStatus.value = RECORDER_STATUS.RECORD;
            // } else {
            conversationMessages.value = [
              ...conversationMessages.value,
              {
                role: "user",
                content: [data.text || ""],
                isShow: true,
                processing: "",
                browseLists: [],
              },
              {
                role: "assistant",
                content: [],
                isShow: false,
                processing: "",
                browseLists: [],
              },
            ];
            //dikirim ke socket
            handleChatSubmitted();
            // }
          } else {
            handleInterupt();
            const avg =
              voiceData.value.result.sum / voiceData.value.result.total;
            voiceData.value.threshold = Math.floor(Math.max(avg, 8));

            if (!isDesktop) {
              if (mosuClient) mosuClient.sendMessage(EVENT.APP.NO_RESULT);
            } else {
              bythenUnity &&
                bythenUnity.sendMessage(
                  "Manager",
                  "QueueEvent",
                  JSON.stringify({ event: EVENT.APP.NO_RESULT })
                );
            }
          }
        } else {
          handleInterupt();
          console.error("Upload failed");
        }
      } catch (error) {
        handleInterupt();
        console.error("Error uploading the file", error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    audioRecorder.recordingBlob,
    handleChatSubmitted,
    audioRecorder.resetAudio,
    audioRecorder,
    bythenUnity,
    mosuClient,
    handleInterupt,
  ]);

  // if recording true Change muted to false when start recording
  // if recording false process transcript or recording blob
  useEffect(() => {
    if (
      !audioRecorder.isRecording &&
      audioRecorder?.mediaRecorder?.state === "inactive"
    ) {
      // FALSE
      if (recorderStatus.value !== RECORDER_STATUS.PROCESS) {
        if (
          audioRecorder.recordingBlob &&
          audioRecorder.recordingBlob.size > 0
        ) {
          recorderStatus.value = RECORDER_STATUS.PROCESS;
          processRecordingBlob();
        } else {
          // recorderStatus.value = RECORDER_STATUS.RECORD;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioRecorder.isRecording, audioRecorder.recordingBlob]);

  // READ AND ACTION ON RECOREDER_STATUS
  useEffect(() => {
    if (recorderStatus.value === RECORDER_STATUS.RECORD) {
      audioRecorder.startRecording();
    } else if (recorderStatus.value === RECORDER_STATUS.STOP) {
      audioRecorder.stopRecording();
    } else if (recorderStatus.value === RECORDER_STATUS.PAUSE) {
      // prevent audioRecorder.isRecording value to false
      audioRecorder.pauseRecording();
      audioRecorder.resetAudio();
    } else if (recorderStatus.value === RECORDER_STATUS.INTERUPT) {
      handleInterupt();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recorderStatus.value]);

  return (
    <motion.div
      initial={{ opacity: isMobile ? 1 : 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      className="absolute w-full z-10 bottom-0 px-4 py-[36px] lg:px-12 lg:py-12"
    >
      <div className={`flex justify-between items-center`}>
        {isMobile ? (
          <VoiceEqualizer
            audioRecorder={audioRecorder}
            handleInterupt={handleInterupt}
          />
        ) : (
          <div></div>
        )}
        <div className="flex items-center space-x-3">
          {isMobile ? (
            <>
              <MicState audioRecorder={audioRecorder} />
              <ChatButton />
            </>
          ) : (
            <>
              <VoiceEqualizer
                audioRecorder={audioRecorder}
                handleInterupt={handleInterupt}
              />
              <MicState audioRecorder={audioRecorder} />
            </>
          )}
        </div>
      </div>
    </motion.div>
  );
}
