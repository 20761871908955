import { demoModalDialog } from "@/pages/demo/signal";

function AvatarSelectionDialog() {
  return (
    <>
      <h3 className="text-4xl font-medium mb-3">Change Avatar</h3>
      <p className="mb-6 lg:[mb-8] text-sm text-center">
        Are you sure you want to switch your avatar? Please note that doing so
        will erase your current conversation history.
      </p>
      <button
        onClick={() => demoModalDialog.value.onOk()}
        className="font-medium w-full bg-pumpkin-200 hover:bg-pumpkin-300 border-0 text-[18px] text-alabaster rounded-[56px] pt-4 lg:pt-[18px] pb-3 lg:pb-[14px] px-14"
      >
        Proceed
      </button>
      <button
        onClick={() => demoModalDialog.value.onCancel()}
        className="font-medium w-full bg-[#E2E1E0] hover:bg-[#E2E1E0]/80 border-0 text-[18px] text-[#1A1919] rounded-[56px] pt-4 lg:pt-[18px] pb-3 lg:pb-[14px] px-14"
      >
        Cancel
      </button>
    </>
  );
}

export default AvatarSelectionDialog;
