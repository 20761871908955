import { createContext, useCallback, useEffect } from "react";
import { useSignal, signal, batch, useComputed } from "@preact/signals-react";
import { useIdleTimer } from "react-idle-timer";
import { isDesktop } from "react-device-detect";
import UnityLoader from "./components/UnityLoader";

import { INITAL_MODAL_DIALOG, RECORDER_STATUS } from "../const";

import useVideoStore from "./store/videoStore";
import StreamLoader from "./components/StreamLoader";
import IdleDialog from "./components/Dialog/Idle";
import useAudioRecorder from "./hooks/useAudio";
import InactiveDialog from "./components/Dialog/Inactive";
import HighDemandDialog from "./components/Dialog/HighDemand";
import QueueDialog from "./components/Dialog/Queue";
import { getStarterConversation } from "@/api/demo";
import {
  demoModalDialog,
  isHighDemand,
  isLoadingAvatar,
  selectedAvatar,
  waitingList,
} from "../signal";
import AlertErrorContent from "@/components/Alert/ErrorContent";

export const conversationMode = signal("chat-lite");
export const videoInitalLoad = signal(true);
export const recorderStatus = signal(RECORDER_STATUS.INITIAL);
export const isIdleTalk = signal(false);
export const errorMicAvatar = signal("");
export const chatSuggestion = signal({
  show: true,
  lists: [],
});

export const DemoAvatarContext = createContext(null);

export default function DemoAvatar() {
  const isActive = useSignal(true);
  const isEnded = useSignal(false);

  const audioRecorder = useAudioRecorder(
    { noiseSuppression: true, echoCancellation: true, channelCount: 1 },
    (err) => {
      errorMicAvatar.value = err.message;
    }
  );

  const { mosuClient } = useVideoStore();

  const handleIdle = useCallback(() => {
    isActive.value = false;
    audioRecorder.muteMic(true);
    demoModalDialog.value = {
      ...demoModalDialog.value,
      show: true,
      body: <IdleDialog />,
      onClose: () => {
        isActive.value = true;
        demoModalDialog.value = INITAL_MODAL_DIALOG;
      },
      onCancel: () => {
        isActive.value = true;
        demoModalDialog.value = INITAL_MODAL_DIALOG;
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioRecorder, isActive.value]);

  const isIdleTimerDisabled = useComputed(() => {
    return (
      waitingList.value ||
      isEnded.value ||
      videoInitalLoad.value ||
      isIdleTalk.value
    );
  });

  // Idle
  useIdleTimer({
    onIdle: handleIdle,
    disabled: isDesktop || isIdleTimerDisabled.value,
    timeout: 1000 * 60 * 2, // 2 Min
    throttle: 500,
  });

  const handleEndChat = () => {
    audioRecorder.muteMic(true);
    if (mosuClient) mosuClient?.logout();

    batch(() => {
      isEnded.value = true;
      recorderStatus.value = RECORDER_STATUS.INITIAL;
      demoModalDialog.value = {
        ...demoModalDialog.value,
        show: true,
        body: <InactiveDialog />,
        onClose: () => {
          window.location.reload();
        },
        onCancel: () => {
          window.location.reload();
        },
      };
    });
  };

  // Automatic to end chat
  useIdleTimer({
    onIdle: handleEndChat,
    disabled: isActive.value,
    timeout: 1000 * 30, // 30 sec
    throttle: 500,
  });

  //initiate mic permission on mounting
  const fetchSuggestion = useCallback(async () => {
    try {
      const { data: suggestionData } = await getStarterConversation(
        selectedAvatar.value.messageID
      );

      chatSuggestion.value.lists =
        suggestionData.list_start_chat_template || [];
    } catch (error) {
      return <AlertErrorContent errorMessage={error.message} />;
    }
  }, []);

  useEffect(() => {
    if (selectedAvatar.value.messageID) fetchSuggestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSuggestion, selectedAvatar.value.messageID]);

  const resetState = useCallback(() => {
    batch(() => {
      isLoadingAvatar.value = true;
      videoInitalLoad.value = true;
      selectedAvatar.value = {};
      demoModalDialog.value = INITAL_MODAL_DIALOG;
    });
  }, []);

  useEffect(() => {
    if (isHighDemand.value) {
      demoModalDialog.value = {
        ...demoModalDialog.value,
        show: true,
        body: <HighDemandDialog />,
        onOk: () => {
          waitingList.value = true;
          mosuClient?.logout();

          demoModalDialog.value = {
            ...demoModalDialog.value,
            body: <QueueDialog />,
            onOk: () => {},
            onClose: () => {
              waitingList.value = false;
              resetState();
            },
            onCancel: () => {
              waitingList.value = false;
              resetState();
            },
          };
        },
        onClose: () => {
          resetState();
        },
        onCancel: () => {
          resetState();
        },
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHighDemand.value, resetState]);

  return (
    <DemoAvatarContext.Provider value={{ audioRecorder }}>
      <div className="relative h-[100dvh] overflow-hidden">
        {isDesktop ? <UnityLoader /> : <StreamLoader />}
      </div>
    </DemoAvatarContext.Provider>
  );
}
