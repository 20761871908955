import { Avatar, Tooltip } from "@nextui-org/react";
import { CaretDown, CaretUp, Compass } from "@phosphor-icons/react";
import CopyToClipboard from "copy-to-clipboard";
import { selectedAvatar } from "@/pages/demo/signal";
import { useCallback, useState } from "react";
import DotsLoader from "@/components/Loader/DotsLoader";
import { AnimatePresence, motion, useAnimate } from "framer-motion";
import Image from "next/image";
import { isDesktop } from "react-device-detect";
import { useSignal } from "@preact/signals-react";
import DOMPurify from "dompurify";
import { renderMarkdown } from "@/utils/string";

const tooltipClass = {
  base: [
    "before:bg-[#E9E7DE] before:border-[0.5px] before:border-[#D2D0C8] before:shadow-none border-[0.5px] border-[#D2D0C8] rounded-[4px]",
  ],
  content: [
    "py-[6px] px-[12px]",
    "text-[12px] leading-[16px] bg-[#E9E7DE] border-0 rounded-[4px] shadow-none",
  ],
};

const BrowseComponent = ({ messageContent }) => {
  const [isOpen, setIsOpen] = useState(false);

  if (messageContent.browseLists.length) {
    return (
      <>
        <div className="relative w-full">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <span className="text-xs font-mono mr-2">
              SEARCHED {messageContent.browseLists.length} SITES{" "}
            </span>
            {isOpen ? <CaretUp /> : <CaretDown />}
          </div>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                initial={{
                  height: "0px",
                  opacity: 0,
                  margin: "0",
                }}
                animate={{
                  height: "100%",
                  opacity: 1,
                  margin: "3px 0 5px",
                  transition: {
                    duration: 0.2,
                    ease: "easeIn",
                  },
                }}
                exit={{
                  height: "0px",
                  opacity: 0,
                  margin: "0",
                  transition: {
                    duration: 0.2,
                    ease: "easeOut",
                  },
                }}
                className={`bg-[#FBFAF8] border border-[#EBE7DE] rounded-xl text-sm w-full mt-3 mb-5 overflow-hidden divide-y-1 divide-[#EBE7DE]`}
              >
                {messageContent.browseLists.map((browseList, indexBrowse) => {
                  const { origin } = new URL(browseList.url);
                  return (
                    <div
                      key={indexBrowse}
                      className="flex items-start space-x-2 px-2 py-[10px]"
                    >
                      <Image
                        unoptimized
                        src={`${origin}/favicon.ico`}
                        alt={`${browseList.name}-favicon`}
                        width="18"
                        height="18"
                        className="mt-1"
                      />
                      <a href={browseList.url} target="_blank" rel="noreferrer">
                        <p className="font-medium text-[#6C675F]">
                          {browseList.name}
                        </p>
                        <p className="text-[#D7CEBD]">
                          {origin.split("www.")[1]}
                        </p>
                      </a>
                    </div>
                  );
                })}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </>
    );
  }

  return null;
};

export function ChatHistory({
  message,
  isLastMessage,
  onRegenerateChat,
  onReportAnswer,
}) {
  const isCopying = useSignal(false);
  const onClickThumbDown = () => {
    onReportAnswer(message.chatId);
  };

  const onClickCopy = useCallback(() => {
    CopyToClipboard(message.content.join(""));
    isCopying.value = true;
    setTimeout(() => {
      isCopying.value = false;
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCopying.value]);

  return (
    <div
      className={`w-full flex ${
        message.role === "user" ? "flex-row-reverse" : "group"
      } ${message.processing ? "items-center" : ""}`}
    >
      {message.role !== "user" && isDesktop ? (
        <div className="mr-6">
          <Avatar className="w-12 h-12" src={selectedAvatar.value.image} />
        </div>
      ) : null}

      <div
        className={`rounded-3xl rounded-br-none text-lg text-[#1A1919] h-fit mt-3 relative ${
          message.role === "user"
            ? "bg-[#EBE7DE] px-[18px] py-[14px] max-w-[240px] lg:max-w-[384px]"
            : "bg-transparent"
        }`}
      >
        {!message.isShow ? (
          <>
            {message.processing === "search" ? (
              <div className="flex items-center space-x-[6px]">
                <Compass weight="fill" color="#9B9488" size={18} />{" "}
                <span className="text-xs font-mono">BROWSING</span>
              </div>
            ) : (
              <div className="w-8 h-11 -mt-2">
                <DotsLoader />
              </div>
            )}
          </>
        ) : (
          <>
            <BrowseComponent messageContent={message} />
            <AnimatePresence key="chatHistory">
              {message.content.map((msgContent, indexContent) => {
                const sanitizedHTML = DOMPurify.sanitize(
                  renderMarkdown(msgContent[0])
                );

                if (message.role == "user") {
                  return <span key={indexContent}>{msgContent}</span>;
                }

                return (
                  <motion.span
                    key={indexContent}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 2 }}
                    className="text-base tracking-wide lg:text-lg [&_p]:inline"
                    dangerouslySetInnerHTML={{
                      __html: sanitizedHTML,
                    }}
                  ></motion.span>
                );
              })}
            </AnimatePresence>

            {message.role !== "user" && (
              <div
                className={`${
                  !isLastMessage &&
                  "transition-opacity opacity-0 group-hover:opacity-100 absolute top-[100%] w-auto"
                } w-full mt-2 -ml-[6px] space-x-[-2px] flex items-center text-base text-[#7E7D7C]`}
              >
                <Tooltip
                  // closeDelay={100000}
                  classNames={tooltipClass}
                  placement="bottom"
                  showArrow={true}
                  content="Copy"
                >
                  <button
                    onClick={onClickCopy}
                    className={`flex items-center space-x-1 px-[4px] py-[2px] rounded ${
                      isCopying.value
                        ? "bg-[#E9E7DE]"
                        : "group/button hover:bg-[#E9E7DE]"
                    }`}
                  >
                    <span className="!text-base material-symbols-outlined">
                      {isCopying.value ? "check" : "content_copy"}
                    </span>
                  </button>
                </Tooltip>
                {isLastMessage && (
                  <Tooltip
                    classNames={tooltipClass}
                    placement="bottom"
                    showArrow={true}
                    content="Regenerate"
                  >
                    <button
                      onClick={() => onRegenerateChat()}
                      className="flex items-center space-x-1 px-[6px] py-[4px] rounded group/button hover:bg-[#E9E7DE]"
                    >
                      <span className="text-[#7E7D7C] !text-base material-symbols-outlined group-focus/button:text-[#f04406]">
                        autorenew
                      </span>
                    </button>
                  </Tooltip>
                )}
                {message.chatId && (
                  <Tooltip
                    classNames={tooltipClass}
                    placement="bottom"
                    showArrow={true}
                    content={
                      message.is_feedback_bad
                        ? "Thank you for your feedback"
                        : "Bad Response"
                    }
                  >
                    <button
                      style={{
                        "--color-thumb": message.is_feedback_bad
                          ? "#f04406"
                          : "#7E7D7C",
                      }}
                      onClick={onClickThumbDown}
                      className="flex items-center space-x-1 px-[6px] py-[4px] rounded group/button hover:bg-[#E9E7DE]"
                    >
                      <span className="text-[var(--color-thumb)] !text-base material-symbols-outlined">
                        thumb_down
                      </span>
                    </button>
                  </Tooltip>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
