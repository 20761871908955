import { useState } from "react";
import { selectedAvatar } from "@/pages/demo/signal";
import AvatarMainLoadingScreen from "./main";
import DonPenguLoadingScreen from "./DonPengu";
import { useIdleTimer } from "react-idle-timer";
import * as Sentry from "@sentry/nextjs";
import { isDesktop } from "react-device-detect";

const LoadingScreen = ({ progressWidth }) => {
  const [isSentrySent, setIsSentrySent] = useState(false);

  useIdleTimer({
    onIdle: () => {
      Sentry.captureEvent({
        message: "User waited avatar too long!",
        level: "error", // You can set the level (e.g., 'info', 'warning', 'error')
        extra: {
          additionalInfo: {
            avatar_name: selectedAvatar.value.name,
            avatar_id: selectedAvatar.value.id,
            is_webgl: isDesktop ? true : false,
            is_streaming: isDesktop ? false : true,
          },
        },
        tags: {
          feature: "demo-webgl-and-stream",
        },
      });
      setIsSentrySent(true);
    },
    disabled: progressWidth !== 90 && isSentrySent,
    timeout: 1000 * 30, // 30s
    throttle: 500,
  });

  if (selectedAvatar.value.name === "don_pengu") {
    return <DonPenguLoadingScreen progressWidth={progressWidth} />;
  }

  return <AvatarMainLoadingScreen progressWidth={progressWidth} />;
};

export default LoadingScreen;
