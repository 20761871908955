import { useCallback, useEffect, useRef, useState } from "react";
import { EVENT, INITAL_MODAL_DIALOG } from "../../../const";
import { conversationMode, videoInitalLoad } from "../..";
import useVideoStore, { WS_STATUS } from "../../store/videoStore";
import VideoChat, { conversationMessages } from "../VideoChat";
import {
  chatToken,
  demoModalDialog,
  isLoadingAvatar,
  selectedAvatar,
} from "@/pages/demo/signal";
import WebsocketDisconnect from "../Dialog/WebsocketDisconnect";
import { AnimatePresence, motion } from "framer-motion";
import LoadingScreen from "../LoadingScreen";
import FloatingVideo from "./FloatingVideo";
import FullScreenVideo from "./FullScreenVideo";
import { useRouter } from "next/router";

function StreamLoader() {
  const isFirstLoad = useRef(true);
  const { query } = useRouter();
  const [progressWidth, setProgressWidth] = useState(0);

  const {
    init,
    status: wsStatus,
    stream: wsStream,
    initialRTC,
    disconnectReason,
    mosuClient,
    eventMessage,
  } = useVideoStore();

  const onHandleHide = async () => {
    setProgressWidth(100);
    setTimeout(() => {
      isLoadingAvatar.value = false;
      selectedAvatar.value.refetch = false;
      if (query.slug || window.location.origin.includes("donpengu.ai")) {
        conversationMode.value = "chat-full";
        const title = window.location.origin.includes("donpengu.ai")
          ? "Get ready to interact with Don Pengu! Tap start to begin."
          : "Ready to chat with your Byte? Tap start to begin.";
        demoModalDialog.value = {
          ...demoModalDialog.value,
          show: true,
          body: (
            <>
              <p>{title}</p>
              <button
                onClick={() => {
                  conversationMode.value = "chat-lite";
                  demoModalDialog.value = INITAL_MODAL_DIALOG;
                }}
                className="font-medium w-full bg-pumpkin-200 hover:bg-pumpkin-300 border-0 text-alabaster rounded-[56px] pt-4 lg:pt-[18px] pb-3 lg:pb-[14px] px-14"
              >
                Start
              </button>
            </>
          ),
          onClose: () => {
            conversationMode.value = "chat-lite";
            demoModalDialog.value = INITAL_MODAL_DIALOG;
          },
          hideCloseButton: true,
          bodyClass: "px-7 py-6 gap-7",
        };
      } else {
        conversationMode.value = "chat-lite";
      }
    }, 1500);
  };

  // SET RECORDER INITIAL STATUS ON WS/UNITY
  useEffect(() => {
    if (eventMessage) {
      if (eventMessage.includes("hide-loading-screen")) {
        onHandleHide();
      }

      if (eventMessage.includes("show-loading-screen")) {
        setProgressWidth(90);
        isLoadingAvatar.value = true;
      }
    }
  }, [eventMessage]);

  useEffect(() => {
    if (wsStatus == WS_STATUS.CONNECTED) {
      setProgressWidth(80);
      if (wsStream) {
        if (isFirstLoad.current) {
          mosuClient.sendMessage(EVENT.APP.CHANGE_CHARACTER, {
            data: {
              avatarID: selectedAvatar.value.avatarId,
            },
          });

          isFirstLoad.current = false;
        }
      }
    } else if (wsStatus == WS_STATUS.DISCONNECTED) {
      setProgressWidth(0);
      isLoadingAvatar.value = true;
      videoInitalLoad.value = true;
      selectedAvatar.value = {};
      mosuClient?.logout();
      demoModalDialog.value = {
        ...demoModalDialog.value,
        show: true,
        body: <WebsocketDisconnect reason={disconnectReason.toString()} />,
        onOk: () => {
          // selectedAvatar.value = {};
          demoModalDialog.value = INITAL_MODAL_DIALOG;
        },
        onClose: () => {
          demoModalDialog.value = INITAL_MODAL_DIALOG;
        },
      };
    } else if (wsStatus === WS_STATUS.RECONNECTING) {
      setProgressWidth(0);
      mosuClient?.logout();
      init();
    } else if (wsStatus === WS_STATUS.CONNECTING) {
      setProgressWidth(50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsStatus]);

  useEffect(() => {
    if (chatToken.value && !initialRTC) init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatToken.value, initialRTC, init]);

  useEffect(() => {
    return () => {
      mosuClient?.logout();
    };
  }, [mosuClient]);

  return (
    <div
      style={{
        "--bottom-pos-button": conversationMessages.value.length
          ? "230px"
          : "330px",
        "--bottom-pos-video": conversationMessages.value.length
          ? "216px"
          : "316px",
        "--dragarea-height": conversationMessages.value.length
          ? "calc(100dvh - 223px)"
          : "calc(100dvh - 338px)",
      }}
    >
      {/* <button
        className="text-3xl text-pumpkin-400 absolute top-20 z-[999]"
        onClick={() => mosuClient?.logout()}
      >
        DISCONNECT
      </button> */}

      <AnimatePresence key="FloatingVideo">
        {conversationMode.value === "chat-lite" && (
          <FloatingVideo wsStream={wsStream} />
        )}
      </AnimatePresence>

      {conversationMode.value === "voice" && (
        <FullScreenVideo wsStream={wsStream} />
      )}

      <AnimatePresence key="VideoToggle">
        {conversationMode.value === "chat-full" && (
          <motion.div
            key={"openFloating"}
            initial={{ right: -23, opacity: 0 }}
            animate={{ right: 0, opacity: 1 }}
            transition={{
              duration: 0.5,
              delay: 0.3,
            }}
            exit={{
              right: -23,
              opacity: 0,
              transition: {
                duration: 0,
              },
            }}
            onClick={() => (conversationMode.value = "chat-lite")}
            className={`absolute bottom-[var(--bottom-pos-button)] z-[60] flex items-center justify-center bg-[#6C675F] rounded-none rounded-l-lg h-[90px] w-[24px]`}
          >
            <span className="material-symbols-outlined text-white ml-1">
              chevron_left
            </span>
          </motion.div>
        )}
      </AnimatePresence>
      {!isLoadingAvatar.value && !selectedAvatar.value.refetch && <VideoChat />}
      <AnimatePresence key="LoadingStream">
        {isLoadingAvatar.value && selectedAvatar.value.refetch && (
          <LoadingScreen progressWidth={progressWidth} />
        )}
      </AnimatePresence>
    </div>
  );
}

export default StreamLoader;
