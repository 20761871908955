import ReactMarkdown from "react-markdown";
import { Avatar } from "@nextui-org/react";
import { AnimatePresence, motion } from "framer-motion";
import { selectedAvatar } from "@/pages/demo/signal";
import { isMobile } from "react-device-detect";
import { chatSuggestion, conversationMode } from "../..";
import Image from "next/image";
import { useComputed } from "@preact/signals-react";

const SuggestionAnimation = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    delay: isMobile ? 0 : 800,
  },
};

function ChatSuggestion({ onHandleMessageInput }) {
  const containerClass = useComputed(() => {
    return conversationMode.value !== "chat-full"
      ? "max-w-full delay-300"
      : "max-w-[460px] delay-0";
  });

  return (
    <div className="h-full px-3 min1025:h-[calc(100%-153px)] flex flex-col items-center justify-between w-[calc(100%+32px)] -mx-4 min1025:mx-0 min1025:w-full min1025:justify-start pb-3 min1025:pb-0">
      <Avatar
        className="invisible min1025:visible w-[72px] h-[72px] my-9"
        src={selectedAvatar.value.image}
      />
      <AnimatePresence>
        {chatSuggestion.value.lists.length ? (
          <motion.div
            variants={SuggestionAnimation}
            initial="initial"
            animate="animate"
            className={`flex w-full space-x-4 px-4 min1025:px-0 py-[6px] min1025:py-0 overflow-scroll min1025:overflow-visible no-scrollbar min1025:space-x-0 min1025:grid min1025:grid-cols-2 min1025:gap-3 transition-all duration-300 ${containerClass}`}
          >
            {chatSuggestion.value.lists.map(
              ({ template, icon_url }, indexSuggestion) => (
                <div
                  key={indexSuggestion}
                  className="px-4 py-3 min-w-[221px] min1025:min-w-auto border border-analog_beige-400 rounded-xl flex flex-col gap-3 cursor-pointer min1025:transition-all min1025:duration-300 hover:scale-105 hover:shadow-md"
                  onClick={() => onHandleMessageInput(template)}
                >
                  <Image
                    unoptimized
                    src={icon_url}
                    alt="icon suggestion chat"
                    width={30}
                    height={30}
                  />
                  <ReactMarkdown className="text-[#1a1919] text-sm">
                    {template}
                  </ReactMarkdown>
                </div>
              )
            )}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
}

export default ChatSuggestion;
