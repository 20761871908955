import { useEffect, useState } from "react";
import { icon1, icon2, icon3, icon4 } from "./paths";
import { useMotionValue, animate } from "framer-motion";
import Image from "next/image";

const paths = [icon1, icon2, icon3, icon4, icon1];

export default function IconTransformed() {
  const [pathIndex, setPathIndex] = useState(0);
  const progress = useMotionValue(pathIndex);

  useEffect(() => {
    const animation = animate(progress, pathIndex, {
      duration: 0.8,
      ease: "easeInOut",
      onComplete: () => {
        if (pathIndex === paths.length - 1) {
          progress.set(0);
          setPathIndex(1);
        } else {
          setPathIndex(pathIndex + 1);
        }
      },
    });

    return () => animation.stop();
  }, [pathIndex]);

  return (
    <Image
      width={200}
      height={200}
      src={paths[[pathIndex]]}
      alt="loading-assets"
      className="w-[150px] lg:w-[200px]"
    />
  );
}
