import crypto from "crypto";

function generateVerifier() {
  const verifier = crypto
    .randomBytes(32)
    .toString("base64")
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
  return verifier;
}

function generateChallenge(verifier) {
  const hash = crypto.createHash("sha256").update(verifier).digest();
  const challenge = hash
    .toString("base64")
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
  return challenge;
}

export const getCodeChallenge = () => {
  const codeVerifier = generateVerifier();
  const codeChallange = generateChallenge(codeVerifier);
  return {
    codeChallange,
    codeVerifier,
  };
};

export const transformLinks = (text) => {
  // Regular expression to match href links
  var regex = /(?:https?|ftp):\/\/[^\s]+/g;

  // Replace each matched link with an anchor tag
  return text.replace(regex, function (match) {
    return '<a class="text-button_blue" href="' + match + '">' + match + "</a>";
  });
};

export const chunkArray = (array, chunkSize) => {
  let chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export default function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Use padStart to ensure that single-digit minutes and seconds are prefixed with '0'
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
}
