function DotsLoader() {
  return (
    <div className="flex space-x-[2px] justify-center items-center h-full w-full">
      <div className="h-2 w-2 bg-[#4C4B4B] rounded-full animate-bounce [animation-delay:-0.3s]"></div>
      <div className="h-2 w-2 bg-[#4C4B4B] rounded-full animate-bounce [animation-delay:-0.15s]"></div>
      <div className="h-2 w-2 bg-[#4C4B4B] rounded-full animate-bounce"></div>
    </div>
  );
}

export default DotsLoader;
