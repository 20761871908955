import { demoModalDialog } from "@/pages/demo/signal";

function LimitExceedDialog() {
  return (
    <>
      <h3 className="text-2xl lg:text-4xl font-medium">Limit Exceeded</h3>
      <p className="mb-7 lg:[mb-8] text-lg text-center tracking-[0.18px]">
        You&apos;ve reached the maximum allowed interactions with our demo.
      </p>
      <button
        onClick={() => demoModalDialog.value.onOk()}
        className="font-medium w-full bg-pumpkin-200 hover:bg-pumpkin-300 border-0 text-alabaster rounded-[56px] pt-4 lg:pt-[18px] pb-3 lg:pb-[14px] px-14"
      >
        Return
      </button>
    </>
  );
}

export default LimitExceedDialog;
