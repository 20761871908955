import { motion } from "framer-motion";
import { selectedAvatar } from "@/pages/demo/signal";
import AbanoubLoader from "@/components/Loader/AbanoubLoader";

const AvatarMainLoadingScreen = ({ progressWidth }) => {
  return (
    <motion.div
      initial={{ top: "0" }}
      animate={{ top: "0" }}
      exit={{ top: "-100%" }}
      transition={{ duration: 1 }}
      className={`${selectedAvatar.value.loaderBG} h-[100dvh] z-[999] flex flex-col justify-between p-4 pb-8 lg:p-16 lg:pb-16 items-center absolute w-full`}
    >
      <div></div>
      <AbanoubLoader />
      <div className="w-full">
        <div className="relative h-[2px] bg-[#0E100F]/20">
          <motion.div
            initial={{ width: 0 }}
            animate={{
              width: progressWidth + "%",
            }}
            transition={{
              duration: 1.5,
            }}
            className="h-[2px] bg-[#0E100F]"
          ></motion.div>
        </div>
        <div className="flex mt-3 justify-between items-center text-[#1A1919]">
          <span className="font-mono text-xs font-medium">
            LOADING - {progressWidth}%
          </span>
          <span className="font-mono text-xs font-medium">BYTE DEMO</span>
        </div>
      </div>
    </motion.div>
  );
};

export default AvatarMainLoadingScreen;
