import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

import { videoInitalLoad } from "../..";
import VideoChat, { isAiStartTalk } from "../VideoChat";

import { isLoadingAvatar, selectedAvatar } from "@/pages/demo/signal";
import { EVENT } from "@/pages/demo/const";
import { ENVIRONMENT } from "@/utils/environment";
import { AnimatePresence } from "framer-motion";
import LoadingScreen from "../LoadingScreen";

function UnityLoader() {
  const isFirstLoad = useRef(true);
  const [isShowChat, setIsShowChat] = useState(false);

  const {
    removeEventListener,
    addEventListener,
    unload,
    isLoaded,
    sendMessage,
    loadingProgression,
    unityProvider,
  } = useUnityContext({
    loaderUrl: `${ENVIRONMENT.ASSETS_URL}/webgl-demo/Build/WebGLBuild.loader.js`,
    dataUrl: `${ENVIRONMENT.ASSETS_URL}/webgl-demo/Build/WebGLBuild.data.unityweb`,
    frameworkUrl: `${ENVIRONMENT.ASSETS_URL}/webgl-demo/Build/WebGLBuild.framework.js.unityweb`,
    codeUrl: `${ENVIRONMENT.ASSETS_URL}/webgl-demo/Build/WebGLBuild.wasm.unityweb`,
    streamingAssetsUrl: `${ENVIRONMENT.ASSETS_URL}/webgl-demo/StreamingAssets`,
  });

  const propsUnity = useMemo(() => {
    return {
      isLoaded,
      loadingProgression,
      unityProvider,
      removeEventListener,
      addEventListener,
      unload,
      sendMessage,
    };
  }, [
    isLoaded,
    loadingProgression,
    unityProvider,
    addEventListener,
    removeEventListener,
    sendMessage,
    unload,
  ]);

  // HANDLE UNITY EVENT LISTENER
  const onShowHideLoadingScreen = useCallback((status) => {
    isLoadingAvatar.value = status.show;
    setIsShowChat(false);

    if (!status.show) {
      isAiStartTalk.value = false;
      videoInitalLoad.value = true;
      selectedAvatar.value.refetch = false;
    }
  }, []);

  useEffect(() => {
    return () => {
      if (propsUnity) {
        removeEventListener("SignalHideLoadingScreen", () =>
          onShowHideLoadingScreen({ show: false })
        );
        removeEventListener("SignalShowLoadingScreen", () =>
          onShowHideLoadingScreen({ show: true })
        );
        addEventListener("SignalReadyChat", () => setIsShowChat(true));
        unload();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoaded && isFirstLoad.current) {
      sendMessage(
        "Manager",
        "QueueEvent",
        JSON.stringify({
          event: EVENT.APP.CHANGE_CHARACTER,
          data: {
            avatarID: selectedAvatar.value.avatarId,
          },
        })
      );
      addEventListener("SignalHideLoadingScreen", () =>
        onShowHideLoadingScreen({ show: false })
      );
      addEventListener("SignalShowLoadingScreen", () =>
        onShowHideLoadingScreen({ show: true })
      );
      addEventListener("SignalReadyChat", () => setIsShowChat(true));
      isFirstLoad.current = false;
    }
  }, [addEventListener, isLoaded, onShowHideLoadingScreen, sendMessage]);

  return (
    <>
      <Unity
        id={"bythen-unity-canvas"}
        unityProvider={unityProvider}
        className="absolute w-screen h-[100dvh] z-10 no-scrollbar"
      />
      {!isLoadingAvatar.value &&
        !selectedAvatar.value.refetch &&
        isShowChat && <VideoChat bythenUnity={propsUnity} />}

      <AnimatePresence>
        {isLoadingAvatar.value && selectedAvatar.value.refetch && (
          <LoadingScreen progressWidth={Math.round(loadingProgression * 100)} />
        )}
      </AnimatePresence>
    </>
  );
}

export default UnityLoader;
