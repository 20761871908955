import { Button, Tooltip } from "@nextui-org/react";
import { Microphone, MicrophoneSlash } from "@phosphor-icons/react";
import React, { useCallback } from "react";
import { recorderStatus } from "../..";
import { RECORDER_STATUS } from "@/pages/demo/const";

function MicState({ audioRecorder }) {
  const onClickMic = useCallback(
    (type) => {
      if (type == "on") {
        recorderStatus.value = RECORDER_STATUS.RECORD;
        audioRecorder.muteMic(false);
      } else if (type == "off") {
        recorderStatus.value = RECORDER_STATUS.PAUSE;
        audioRecorder.muteMic(true);
      }
    },
    [audioRecorder]
  );

  if (audioRecorder.isMuted) {
    return (
      <Tooltip
        classNames={{
          base: ["before:bg-[#F5F3F0] border-0 border-[#F5F3F0]"],
          content: [
            "py-[6px] px-[12px] border-0",
            "text-[12px] leading-[16px] bg-[#F5F3F0] border-[#F5F3F0] rounded-[4px] shadow-none",
          ],
        }}
        showArrow={true}
        content="Turn on microphone"
      >
        <Button
          className="w-[62px] h-[54px] bg-[#E12120] data-[hover=true]:brightness-90 data-[hover=true]:opacity-100 data-[hover=true]:blur-none"
          color="primary"
          style={{ opacity: 100 }}
          isIconOnly
          size="lg"
          aria-label="Microphone Off"
          radius="full"
          disabled={recorderStatus.value === RECORDER_STATUS.PROCESS}
          onClick={() => onClickMic("on")}
        >
          <span className="!text-[26px] text-[#F5F3EF] material-symbols-outlined">
            mic_off
          </span>
        </Button>
      </Tooltip>
    );
  }

  return (
    <>
      {audioRecorder.mediaRecorder ? (
        <Tooltip
          classNames={{
            base: ["before:bg-[#F5F3F0] border-0 border-[#F5F3F0]"],
            content: [
              "py-[6px] px-[12px] border-0",
              "text-[12px] leading-[16px] bg-[#F5F3F0] border-[#F5F3F0] rounded-[4px] shadow-none",
            ],
          }}
          showArrow={true}
          content="Turn off microphone"
        >
          <Button
            className="w-[62px] h-[54px] bg-[#F5F3EF] data-[hover=true]:brightness-90 data-[hover=true]:opacity-100 data-[hover=true]:blur-none"
            color="primary"
            style={{ opacity: 100 }}
            isIconOnly
            size="lg"
            aria-label="Microphone On"
            radius="full"
            disabled={recorderStatus.value === RECORDER_STATUS.PROCESS}
            onClick={() => onClickMic("off")}
          >
            <span className="!text-[26px] text-[#1A1919] material-symbols-outlined">
              mic
            </span>
          </Button>
        </Tooltip>
      ) : null}
    </>
  );
}

export default MicState;
