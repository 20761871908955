import { useCallback, useContext, useEffect, useMemo } from "react";
import { SSE } from "sse.js";
import { useRouter } from "next/router";
import { AnimatePresence, motion } from "framer-motion";
import { isDesktop, isMobile } from "react-device-detect";
import Cookies from "js-cookie";

import { signal } from "@preact/signals-react";
import {
  demoModalDialog,
  isShowBythenWatermark,
  selectedAvatar,
} from "@/pages/demo/signal";
import { ENVIRONMENT } from "@/utils/environment";

import ConversationToggle from "./ConversationToggle";

import ChatRoom from "../ChatRoom";
import VoiceAssistant from "../VoiceAssistant";
import CharacterSelection from "../CharacterSelection";
import LimitExceedDialog from "../Dialog/LimitExceed";

import {
  DemoAvatarContext,
  conversationMode,
  recorderStatus,
  videoInitalLoad,
} from "../..";
import useVideoStore from "../../store/videoStore";

import { EVENT, INITAL_MODAL_DIALOG, RECORDER_STATUS } from "../../../const";
import PoweredBythen from "../PoweredBythen";

export const conversationMessages = signal([]);
export const messageContent = signal([]);

export const isAiStartTalk = signal(false);

let source;

export default function VideoChat({ bythenUnity }) {
  const { audioRecorder } = useContext(DemoAvatarContext);
  const { mosuClient, eventMessage } = useVideoStore();
  const { query } = useRouter();

  const handleChatSubmitted = useCallback(
    async (action) => {
      let endpointUrl = `${ENVIRONMENT.API_BYTHEN}ms/chat-bot/v1/avatars/${selectedAvatar.value.messageID}/stream_messages`;

      let payload = JSON.stringify({
        messages: conversationMessages.value
          .filter(
            (_, indexMessage) =>
              indexMessage === conversationMessages.value.length - 2
          )
          .map((msg) => {
            return {
              ...msg,
              content: msg.content.join(""),
            };
          }),
      });

      if (action?.isRegenerate)
        endpointUrl = `${ENVIRONMENT.API_BYTHEN}ms/chat-bot/v1/chats/regenerate`;

      source = new SSE(endpointUrl, {
        headers: {
          "Content-Type": "application/json",
          "User-Token": Cookies.get("DEMO_TOKEN"),
          "Session-Token": selectedAvatar.value.token,
        },
        ...(!action?.isRegenerate && { payload }),
        method: "POST",
      });

      if (!isDesktop) {
        if (mosuClient) mosuClient.sendMessage(EVENT.APP.START_PROCESS);
      } else {
        bythenUnity &&
          bythenUnity.sendMessage(
            "Manager",
            "QueueEvent",
            JSON.stringify({ event: EVENT.APP.START_PROCESS })
          );
      }

      const indexMessage = conversationMessages.value.length - 1;
      source.addEventListener("message", (e) => {
        console.log("🚀 message", e);
        if (!e.data) return;

        const data = JSON.parse(e.data);
        console.log("🚀 ~ source.addEventListener ~ data:", data);

        if (data.content && data.content.length > 0) {
          if (conversationMode.value == "chat-full")
            conversationMessages.value[indexMessage].isShow = true;

          conversationMessages.value[indexMessage].content = [
            ...conversationMessages.value[indexMessage].content,
            data.content,
          ];

          conversationMessages.value[indexMessage].processing = "";

          if (action?.onHandleStart) {
            action.onHandleStart();
          }
        } else {
          if (data.processing) {
            conversationMessages.value[indexMessage].processing =
              data.processing;
            if (data.url_data) {
              conversationMessages.value[indexMessage].browseLists = [
                ...conversationMessages.value[indexMessage].browseLists,
                ...[data.url_data],
              ];
            }
          }

          if (data.chat_id) {
            conversationMessages.value[indexMessage].chatId = data.chat_id;
          }
        }

        conversationMessages.value = [...conversationMessages.value];

        if (data.voice && conversationMode.value !== "chat-full") {
          if (!isDesktop) {
            if (mosuClient)
              mosuClient.sendMessage(EVENT.APP.VOICE, {
                data: {
                  voice: data.voice,
                  emotion: data.emotion,
                },
              });
          } else {
            if (bythenUnity) {
              bythenUnity.sendMessage(
                "Manager",
                "QueueEvent",
                JSON.stringify({
                  event: EVENT.APP.VOICE,
                  data: {
                    voice: data.voice,
                    emotion: data.emotion,
                  },
                })
              );
            }
          }
        }
      });

      source.addEventListener("error", (e) => {
        console.log("🚀 error", e);
        if (!e.data) return;

        const errorInfo = JSON.parse(e.data);

        if (errorInfo.code == "limit_reached") {
          audioRecorder.muteMic(true);
          demoModalDialog.value = {
            ...INITAL_MODAL_DIALOG,
            show: true,
            body: <LimitExceedDialog />,
            onOk: () => {
              window.location.reload();
            },
            onClose: () => {
              window.location.reload();
            },
          };

          if (isDesktop) {
            if (bythenUnity) bythenUnity.unload();
            // bythenUnity = {};
          } else {
            if (mosuClient) mosuClient?.logout();
          }
        }

        if (!isDesktop) {
          if (mosuClient) mosuClient.sendMessage(EVENT.APP.END_PROCESS);
        } else {
          bythenUnity &&
            bythenUnity.sendMessage(
              "Manager",
              "QueueEvent",
              JSON.stringify({ event: EVENT.APP.END_PROCESS })
            );
        }
      });

      source.addEventListener("readystatechange", (e) => {
        if (e.readyState === 2) {
          console.log("🚀 readystatechange:", e);
          //Means end of fetching
          if (!isDesktop) {
            if (mosuClient) mosuClient.sendMessage(EVENT.APP.END_PROCESS);
          } else {
            bythenUnity &&
              bythenUnity.sendMessage(
                "Manager",
                "QueueEvent",
                JSON.stringify({ event: EVENT.APP.END_PROCESS })
              );
          }
          if (action?.onHandleFinish) action.onHandleFinish();
        }
      });

      source.addEventListener("open", () => {
        if (action?.onHandleOpenSSE) {
          action.onHandleOpenSSE();
        }
      });

      source.stream();
    },
    [audioRecorder, bythenUnity, mosuClient]
  );

  // HANDLE UNITY EVENT LISTENER
  const handleStartTalking = () => {
    isAiStartTalk.value = true;
    recorderStatus.value = RECORDER_STATUS.PROCESS;
    conversationMessages.value = conversationMessages.value.map((msg) => ({
      ...msg,
      isShow: true,
    }));
  };

  // HANDLE UNITY EVENT LISTENER
  const handleEndTalking = () => {
    isAiStartTalk.value = false;
    recorderStatus.value = RECORDER_STATUS.RECORD;
  };

  const onDisconnectSSE = () => {
    if (source) source.close();
  };

  // HANDLE END TALK
  useEffect(() => {
    if (eventMessage && !isDesktop) {
      if (eventMessage.includes("end-talking")) {
        handleEndTalking();
      } else if (eventMessage.includes("start-talking")) {
        handleStartTalking();
      }
    }
  }, [eventMessage]);

  useEffect(() => {
    if (bythenUnity) {
      bythenUnity.addEventListener("SignalEndTalking", handleEndTalking);
      bythenUnity.addEventListener("SignalStartTalking", handleStartTalking);
    }
    return () => {
      if (bythenUnity) {
        bythenUnity.removeEventListener(
          "SignalStartTalking",
          handleStartTalking
        );
        bythenUnity.removeEventListener("SignalEndTalking", handleEndTalking);
      }
    };
  }, [bythenUnity]);

  useEffect(() => {
    const onChangeAvatarMode = () => {
      let changeModeValue = 0;

      if (conversationMode.value == "chat-lite")
        changeModeValue = isMobile ? 0 : 1;
      else if (conversationMode.value == "chat-full") changeModeValue = 2;

      if (!isDesktop) {
        if (mosuClient)
          mosuClient.sendMessage(EVENT.APP.CHANGE_MODE, {
            data: { indexMode: changeModeValue },
          });
      } else {
        if (bythenUnity)
          bythenUnity.sendMessage(
            "Manager",
            "QueueEvent",
            JSON.stringify({
              event: EVENT.APP.CHANGE_MODE,
              data: { indexMode: changeModeValue },
            })
          );
      }
    };

    onChangeAvatarMode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationMode.value]);

  // SET RECORDER INITIAL STATUS ON WS/UNITY
  useEffect(() => {
    if (videoInitalLoad.value) {
      if (recorderStatus.value !== RECORDER_STATUS.PROCESS) {
        recorderStatus.value = RECORDER_STATUS.RECORD;
      }

      if (audioRecorder.isMuted) audioRecorder.muteMic(false);
      videoInitalLoad.value = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoInitalLoad.value]);

  const bythenWatermarkColor = useMemo(() => {
    if (isMobile) {
      return conversationMode.value == "voice" ? "white" : "black";
    } else {
      return conversationMode.value == "chat-full" ? "black" : "white";
    }
  }, [conversationMode.value, isMobile]);

  return (
    <>
      {!query.slug && (
        <CharacterSelection
          bythenUnity={bythenUnity}
          disconnectSSE={onDisconnectSSE}
        />
      )}

      {isShowBythenWatermark.value && (
        <motion.div
          key={bythenWatermarkColor}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="w-[120px] lg:w-[200px] absolute top-4 left-4 lg:top-12 lg:left-8 z-[60]"
        >
          <PoweredBythen color={bythenWatermarkColor} />
        </motion.div>
      )}

      <div className="absolute w-full z-10 px-4 bottom-0 lg:px-10 pb-[36px] pt-[90px] bg-[linear-gradient(180deg,_rgba(0,0,0,0)_0%,_rgba(0,0,0,0.6)100%)]"></div>

      {conversationMode.value !== "chat-full" && isDesktop && (
        <ConversationToggle />
      )}

      {conversationMode.value === "voice" && (
        <VoiceAssistant
          bythenUnity={bythenUnity}
          handleChatSubmitted={handleChatSubmitted}
          disconnectSSE={onDisconnectSSE}
        />
      )}

      <AnimatePresence key="chatRoomVideo">
        {conversationMode.value !== "voice" && (
          <ChatRoom
            bythenUnity={bythenUnity}
            handleChatSubmitted={handleChatSubmitted}
            disconnectSSE={onDisconnectSSE}
          />
        )}
      </AnimatePresence>
    </>
  );
}
