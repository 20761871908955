import { Textarea } from "@nextui-org/react";
import { useMemo, useCallback } from "react";
import { RECORDER_STATUS } from "@/pages/demo/const";
import { Microphone, PaperPlaneRight, StopCircle } from "@phosphor-icons/react";
import DotsLoader from "@/components/Loader/DotsLoader";
import formatTime from "@/utils/misc";
import { isProcessing } from ".";
import LiveAudioVisualizer from "../AudioVisualizer";
import { isAiStartTalk, messageContent } from "../VideoChat";
import { conversationMode, recorderStatus } from "../..";
import { isDesktop } from "react-device-detect";

const INPUT_CLASS = {
  innerWrapper: "bg-[#FBFAF8] flex items-end rounded-full",
  inputWrapper: [
    "pr-[10px] p-[9px] pl-[18px] lg:p-2 lg:pl-[24px]",
    "border",
    "border-[#E1DACE]",
    "bg-[#FBFAF8]",
    "group-data-[hover=true]:bg-[#FBFAF8]",
    "group-data-[focus=true]:bg-[#FBFAF8]",
    "group-data-[focus=true]:bg-[#FBFAF8]",
  ],
  input: [
    "placeholder:text-[#B0AFAE] placeholder:pl-0 text-[#1A1919] text-lg resize-none min-h-8 lg:min-h-12 lg:pt-[10px]",
  ],
};

const StartContent = ({ audioRecord }) => {
  if (audioRecord.isRecording) {
    return (
      <div className="flex items-center space-x-4 pb-[6px] lg:pb-3">
        <span>{formatTime(audioRecord.recordingTime)}</span>
        <LiveAudioVisualizer
          colorBar="#4C4B4B"
          mediaRecorder={audioRecord.mediaRecorder}
          barNumber={6}
        />
      </div>
    );
  }

  if (audioRecord.recordingBlob) {
    return (
      <div className="pb-3 lg:pb-[18px]">
        <DotsLoader />
      </div>
    );
  }

  return null;
};

const EndContent = ({
  isOnProcess,
  onStopGenerate,
  startListening,
  onSendingMessage,
}) =>
  isOnProcess ? (
    <button
      aria-label="change-mode"
      className="p-0 leading-none lg:pb-[4px] lg:pr-[4px]"
      onClick={() => onStopGenerate()}
    >
      <span className="material-symbols-outlined text-[#36342F] !text-[35px] lg:!text-[36px]">
        stop_circle
      </span>
    </button>
  ) : (
    <>
      {conversationMode.value !== "voice" && (
        <button
          className={`lg:ml-2 p-0 rounded-full`}
          aria-label="change-mode"
          onClick={() => startListening()}
        >
          <span className="material-symbols-outlined text-[#1A1919] !text-[26px] lg:!text-[28px] lg:!leading-[48px]">
            mic
          </span>
        </button>
      )}
      <button
        className={`ml-[6px] lg:ml-2 p-2 lg:p-[14px] rounded-full transition-colors ${
          messageContent.value
            ? "bg-[#EA3E00]"
            : "bg-[#EBE7DE] cursor-not-allowed"
        }`}
        aria-label="change-mode"
        onClick={onSendingMessage}
      >
        <PaperPlaneRight
          className="w-5"
          size={21}
          weight="fill"
          color={`${messageContent.value ? "#FBFAF8" : "#9B9488"}`}
        />
      </button>
    </>
  );

function TextAreaInput({
  audioRecord,
  onHandleMessageInput,
  handleStopMessage,
  startListening,
}) {
  const onSendingMessage = useCallback(() => {
    if (!messageContent.value) return;
    onStopGenerate();
    onHandleMessageInput(messageContent.value);
  }, [onHandleMessageInput, messageContent.value]);

  const onKeyDownInput = useCallback(
    (e) => {
      if (isProcessing.value) return;
      if (e.keyCode == 13 && !e.shiftKey) {
        e.preventDefault();
        return onSendingMessage();
      }
    },
    [onSendingMessage]
  );

  const onChangeInput = useCallback((ev) => {
    messageContent.value = ev.target.value;
  }, []);

  const isOnProcess = useMemo(() => {
    if (audioRecord.isRecording) return true;
    // if (isAiStartTalk.value) return true;
    if (isProcessing.value) return true;
    // if (recorderStatus.value === RECORDER_STATUS.PROCESS) return true;
    return false;
  }, [audioRecord.isRecording, isProcessing.value]);

  const onStopGenerate = () => {
    if (audioRecord.isRecording) {
      audioRecord.stopRecording();
    } else {
      handleStopMessage();
      isProcessing.value = false;
      isAiStartTalk.value = false;
    }
  };

  return (
    <div className="w-full">
      <div data-lenis-prevent>
        <Textarea
          disableAnimation={true}
          classNames={INPUT_CLASS}
          minRows={1}
          maxRows={3}
          value={messageContent.value}
          radius="full"
          placeholder={
            audioRecord.isRecording || audioRecord.recordingBlob
              ? ""
              : "Write a message..."
          }
          onChange={onChangeInput}
          onKeyDown={onKeyDownInput}
          disabled={audioRecord.isRecording}
          startContent={<StartContent audioRecord={audioRecord} />}
          endContent={
            <EndContent
              isOnProcess={isOnProcess}
              onStopGenerate={onStopGenerate}
              startListening={startListening}
              onSendingMessage={onSendingMessage}
            />
          }
        />
      </div>
      <div className="text-xs lg:text-sm text-[#9B9488] w-full text-center pb-2 px-2 pt-3 lg:pt-6">
        Always double check critical information as byte can make mistakes.
      </div>
    </div>
  );
}

export default TextAreaInput;
