import { demoModalDialog } from "@/pages/demo/signal";

function IdleDialog() {
  return (
    <>
      <h3 className="text-2xl lg:text-4xl font-medium">Are You Still There?</h3>
      <p className="mb-7 lg:[mb-8] text-lg text-center tracking-[0.18px]">
        You have been inactive for too long and have been returned to the
        homepage. Please log in again to continue.
      </p>
      <button
        onClick={() => demoModalDialog.value.onCancel()}
        className="font-medium w-full bg-pumpkin-200 hover:bg-pumpkin-300 border-0 text-alabaster rounded-[56px] pt-4 lg:pt-[18px] pb-3 lg:pb-[14px] px-14"
      >
        Yes, i&apos;m still here
      </button>
    </>
  );
}

export default IdleDialog;
