import { Button } from "@nextui-org/react";
import { HandPalm, StopCircle } from "@phosphor-icons/react";
import React, { useCallback } from "react";
import LiveAudioVisualizer from "../AudioVisualizer";
import { isIdleTalk, recorderStatus, videoInitalLoad } from "../..";
import { RECORDER_STATUS } from "@/pages/demo/const";
import { voiceData } from ".";
import { useComputed } from "@preact/signals-react";
import { useIdleTimer } from "react-idle-timer";
import { isAiStartTalk } from "../VideoChat";

function VoiceEqualizer({ audioRecorder, handleInterupt }) {
  const isIdleTimerDisabled = useComputed(() => {
    if (!isIdleTalk.value) return true;
    if (recorderStatus.value === RECORDER_STATUS.PROCESS) return true;
    if (isAiStartTalk.value) return true;
    if (videoInitalLoad.value) return true;
    if (audioRecorder.isMuted) return true;

    return false;
  });

  const checkIdleAndThreshold = useCallback(
    (value) => {
      if (audioRecorder.isMuted) return;
      const MIN_THRESHOLD = 8;
      if (value - 1 > MIN_THRESHOLD) {
        isIdleTalk.value = false; //active
        if (!voiceData.value.isActive) voiceData.value.isActive = true;
        voiceData.value.result = {
          sum: voiceData.value.result.sum + value,
          total: voiceData.value.result.sum + 1,
        };
      } else {
        isIdleTalk.value = true; //idle
      }
    },
    [audioRecorder.isMuted]
  );

  //HANDLE IDLE TIMER
  useIdleTimer({
    onIdle: (_, idleTimer) => {
      if (voiceData.value.isActive) {
        recorderStatus.value = RECORDER_STATUS.STOP;
      }
    },
    disabled: isIdleTimerDisabled.value,
    timeout: 1000 * 1, //2 Second
    throttle: 1000,
    events: [],
  });

  return (
    <div>
      {recorderStatus.value === RECORDER_STATUS.PROCESS && (
        <div className="flex flex-row lg:flex-row-reverse items-center space-x-[10px] lg:space-x-reverse">
          <Button
            className="w-[62px] h-[54px] bg-[#E12120]"
            color="primary"
            isIconOnly
            size="lg"
            aria-label="Interupt Process"
            radius="full"
            onClick={handleInterupt}
          >
            <StopCircle weight="fill" size={34} color="#ffffff" />
          </Button>
          <span className="text-white font-medium">Generating</span>
        </div>
      )}
      {recorderStatus.value === RECORDER_STATUS.RECORD &&
        !audioRecorder.isMuted && (
          <>
            {audioRecorder.mediaRecorder && (
              <div className="flex flex-row lg:flex-row-reverse items-center space-x-[10px] lg:space-x-reverse">
                <div className="w-[62px] h-[54px] flex items-center justify-center rounded-[100px] bg-black/20">
                  <LiveAudioVisualizer
                    colorBar="white"
                    mediaRecorder={audioRecorder.mediaRecorder}
                    checkIdleAndThreshold={checkIdleAndThreshold}
                    barNumber={3}
                  />
                </div>
                <span className="text-white font-medium">
                  {!isIdleTalk.value ? "Listening" : "Start Speaking"}
                </span>
              </div>
            )}
          </>
        )}
    </div>
  );
}

export default VoiceEqualizer;
