import { useEffect, useRef } from "react";

function FullScreenVideo({ wsStream }) {
  const videoRef = useRef();

  useEffect(() => {
    if (videoRef.current && wsStream) {
      if ("srcObject" in videoRef.current) {
        videoRef.current.srcObject = wsStream;
      } else {
        // @ts-ignore
        videoRef.current.src = window.URL.createObjectURL(wsStream); // for older browsers
      }
    }
  }, [wsStream]);

  return (
    <div className="absolute bottom-0 w-screen h-[100dvh] z-10">
      <div
        id="video-container"
        className="w-full flex justify-center items-center h-full relative"
      >
        <video
          ref={videoRef}
          autoPlay
          webkit-playsinline="true"
          playsInline
          className="w-full h-full object-cover"
        >
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}

export default FullScreenVideo;
